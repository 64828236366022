import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

interface Props{
  open: boolean;
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
  onClick: ()=> void;
}

export default function ContainedButtons({open, onChange, onClick, selectedDate}: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div
        className={selectedDate ? `${classes.activeDate} ${classes.datePickerBox}` : classes.datePickerBox}
        onClick={onClick}
      >
        {selectedDate ? (selectedDate as Date).toLocaleDateString() : "Select Date"}
        <img src={require("./image_calendar.png")} alt="calendar" />
      </div>
      <KeyboardDatePicker
        open={open}
        value={selectedDate}
        onChange={onChange}
        onOpen={onClick}
        onClose={onClick}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        TextFieldComponent={() => null}
        disableFuture={true}
      />
    </MuiPickersUtilsProvider>
  </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: "15px",
  },
  datePickerBox: {
    border: "1px solid #ccc",
    padding: theme.spacing(1),
    cursor: "pointer",
    height: "56px",
      boxSizing: "border-box",
      textAlign: "unset",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "12px",
      borderRadius: 0,
      color: "rgba(124, 124, 124, 1)"
  },
  activeDate:{
    color: "#fff"
  }
}));
