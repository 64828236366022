import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Popover,
  TableContainer,
  MenuItem,
  Input,
  IconButton
} from "@mui/material";
import Headers from '../../../components/src/Headers.web';
import { styled } from '@material-ui/styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from '@material-ui/icons/Search';
import {  ArrowBack } from '@mui/icons-material';
import { configJSON } from "./InviteEmployeeController.web";
import { approvedVerification, pendingVerification, rejectedVerification, calanderIcon, clearVerification, pdfIcon, downloadIcon } from "./assets";
// Customizable Area End
import InviteEmployeeController, {
  Props
} from "./InviteEmployeeController.web";

export default class ViewKycChicklist extends InviteEmployeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDashboardForm = () => {
    const { EditphoneNumber, EditemployeeName,PassportNo ,PassportStatus} = this.state;
    return (
      <Wrapper>
         <Box style={{ padding: "0px", display:"flex" }}>
            <IconButton edge="start">
              <ArrowBack data-testId="backButtonTest_view" onClick={() => this.goToScreen("PeopleManagement")} style={{width: "40px",height: "40px", color: "white"}}/>
            </IconButton>
            <Typography className="home">{configJSON.homeText}</Typography>
        </Box>
        <Box className="contentBox">
          <Typography className="tableHeader">
            {configJSON.uploadVerify}
          </Typography>
        </Box>
        <StyledTableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell className="nameTableCell">{configJSON.nameText}</TableCell>
                <TableCell className="nameTableCell">{configJSON.kycDate}</TableCell>
                <TableCell className="passportTableCell">{configJSON.passportNumber}</TableCell>
                <TableCell className="documentTableCell">{configJSON.documentText}</TableCell>
                <TableCell className="statusTableCell">{configJSON.statusText}</TableCell>
              </TableRow>
            </TableHead>
            {
              <TableBody>                      
                <TableRow>
                  <TableCell>
                    <UserName>
                      <img src="https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png" alt="no image" className="userImg" />
                      <Typography className="nameTxt">{EditemployeeName}</Typography>
                    </UserName>
                  </TableCell>
                  <TableCell className="dobTableTxt">{EditphoneNumber}</TableCell>
                  <TableCell className="passportTextTable">{PassportNo}</TableCell>
                  <TableCell>
                    {
                      ["doc1", "doc2", "doc3", "doc4"].map((document: string) => <TableDocWrapper key={document}>
                        <TableDocWrapper key={document}>
                          <Box className="singleDoc">
                            <img src={downloadIcon} className="downloadBtn" />
                            <img src={pdfIcon} />
                            <Typography className="documentNameText">{configJSON.passportPdf}</Typography>
                          </Box>
                        </TableDocWrapper>
                      </TableDocWrapper>)}
                  </TableCell>
                  <TableCell>
                    <StatusWrapper>
                      {PassportStatus === "approved" || PassportStatus === "rejected" || PassportStatus === "regular" ?
                        <img src={downloadIcon} className="downloadLargeBtn" /> : ''
                      }
                      {this.getStatusButton(PassportStatus)}
                    </StatusWrapper>
                  </TableCell>
                </TableRow> 
              </TableBody>
            }

          </StyledTable>
        </StyledTableContainer>
      </Wrapper>
    )
  }

  getStatusButton = (status: string) => {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const rejectText = this.state.rejectedText;
    switch (status) {
      case 'approved':
        return (
          <Button className="approvedBtn">
            {configJSON.approvedText}
          </Button>
        );
      case 'pending':
        return (
          <Button className="pendingBtn">
            {configJSON.pendingText}
          </Button>
        );
      case 'rejected':
        return (
          <>
            <Button
              className={rejectText ? "rejectedBtn" : "needClarificationBtn"}
              data-testId="rejectTestId"
              onClick={this.handleClick}
              endIcon={<ExpandMoreIcon />}
            >
              {rejectText ? "Rejected" : "Need Clarification"}
            </Button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              data-testId="closeAnchorTestId"
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  marginTop: "10px",
                  backgroundColor: !this.state.rejectedText ? "red" : "#FF7A01",
                  color: "black",

                },
              }}
            >
              <MenuItem className="needClaritiy" data-testId="needClarificationTestId" onClick={this.handleNeedClarification}> {!rejectText ? "Rejected" : "Need Clarification"}
              </MenuItem>
            </Popover>
          </>
        );
      default:
        return null;
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
      {
        <Headers sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn} 
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen = {this.goToScreen}
        isRouteData="PeopleManagement"
        >
          {this.renderDashboardForm()}
      </Headers> 
      }
    </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const Wrapper = styled(Box)({
  background: "#242424",
  padding: "12px 24px",
  height: "100vh",
  boxSizing: "border-box",
  "& .home": {
    fontWeight: 600,
    color: "rgba(252,252,252,1)",
    fontSize: "40px",
    lineHeight: "48px",
    letterSpacing: "-2%",
    fontFamily: "Lato",
  },
  "& .headerBox": {
    marginTop: "18px",
    color: "rgba(252,252,252,1)",
    display: "flex",
    justifyContent: "space-between"
  },
  "& .itemBox": {
    background: "rgba(26,29,31,1)",
    padding: "24px 19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap:"15px"
  },
  "& .itemBoxText": {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Lato",
    latterSpacing: "-1%",
  },
  "& .lastBox": {
    width: "240px",
  },
  "& .loadMoreBtnBox": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px"
  },
  "& .loadMoreBtn": {
    textTransform: "none",
    color: "white",
    height: "48px",
    border: "2px solid rgba(39, 43, 48, 1)",
    fontSize: "15px",
    fontWeight: 700,
    borderRadius: "12px",
    fontFamily: 'Lato',
  },
  "& .itemBoxCount": {
    fontWeight: 500,
    fontSize: "30px",
    fontFamily: 'Lato',
  },
  "& .itemBoxIcon": {},
  "& .contentBox": {
    marginTop: "31px",
    background: "rgba(26,29,31,1)",
    color: "rgba(252,252,252,1)",
    padding: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .tableHeader": {
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "40px",
    latterSpacing: "-2%",
  },
  dialogFirstInputField: {
    width: "100%", borderRadius: '8px',
    fontWeight: 600,
    border: '0.5px solid #F1F5F9',
    background: '#F1F5F9',
  },
  "& .datePicker": {
    border: "2px solid #808080",
    width: "180px",
    height: "44px",
    padding: "10px 18px",
    color: "#808080",
  },
  "& .calnderStyle": {
    border: "2px solid rgba(80, 80, 80, 1)",
    width: "180px",
    height: "42px",
    color: "rgba(80, 80, 80, 1)",
    marginTop: "0px",
    paddingBottom: "10px"
  },
  "& .placeholder-white::placeholder": {
    color: "white",
    "& .MuiInputBase-input":
    {
      paddingLeft: "36px"
    }
  },
});

const customStyles = {
  noDataFoundDiv: {
    textAlign: 'center'
  } as React.CSSProperties,
  noDataFoundBox: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 0px",
  },
  noDataFoundText: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Open Sans",
    color: "#390689"
  },
}

const StyledTableContainer = styled(TableContainer)({
  width: "Fixed (1,264px)px",
  height: "Fixed (41px)px",
  gap: "24px",
  opacity: "0px",

  "& .userImg": {
    width: "48px",
    height: "48px",
    borderRadius: "48px"
  }
});

const TableDocWrapper = styled(Box)({
  "& .singleDoc": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .downloadBtn": {
    width: "16px",
    height: "16px"
  }
});

const StyledTable = styled(Table)({
  backgroundColor: "#18191b",
  "& .MuiTableCell-root": {
    verticalAlign: "top",
    borderBottom: "1px solid #272B30",
  },
  "& .MuiTableCell-head": {
    color: "#fff",
    width: "max-content",
  },
  "& .MuiTableCell-body": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600
  },
  "& .nameTxt": {
    color: "rgba(252, 252, 252, 1)",
    fontWeight: 700,
    fontFamily: 'Lato',
    height: "24px",
    width: "114px",
    fontSize: "15px"
  },
  "& .dobTableTxt": {
    fontWeight: 600,
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontFamily: 'Lato',
    height: "81px",
    width: "24px"
  },
  "& .countryTableText": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    width: "84px",
    height: "24px",
    fontFamily: 'Lato',
    whiteSpace: "nowrap"
  },
  "& .passportTextTable": {
    width: "81px",
    height: "24px",
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontFamily: 'Lato',
    fontWeight: 600,
  },
  "& .nationalityText": {
    width: "80px",
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Lato',
    height: "24px"
  },
  "& .addressText": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Lato',
    width: "249px",
    height: "48px"
  },
  "& .lato": {
    fontFamily: 'Lato'
  },
  "& .dobTableCell": {
    width: "78px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600,
    whiteSpace: "nowrap"

  },
  "& .nameTableCell": {
    width: "36px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .nationalityTableCell": {
    color: 'rgba(211, 211, 211, 1)',
    width: "68px",
    fontFamily: 'Lato',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .countryTableCell": {
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    width: "50px",
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .addressTableCell": {
    width: "50px",
    color: 'rgba(211, 211, 211, 1)',
    fontFamily: 'Lato',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .passportTableCell": {
    width: "106px",
    fontFamily: 'Lato',
    fontWeight: 600,
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
  },
  "& .documentTableCell": {
    gap: '0px',
    width: "70px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .statusTableCell": {
    width: "70px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    fontWeight: 600,
    height: '16px',
    gap: '0px',
    opacity: "0px",
  },
  "& .documentNameText": {
    fontFamily: 'Lato',
    fontWeight: 600,
    color: "rgba(111, 118, 126, 1)"
  }
});

const UserName = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px"
});

const StatusWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "& .approvedBtn": {
    backgroundColor: '#41d613',
    color: 'rgba(36, 36, 36, 1)',
    width: "76px",
    height: "28px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  },
  "& .pendingBtn": {
    backgroundColor: 'yellow',
    color: 'rgba(36, 36, 36, 1)',
    width: "80px",
    height: "28px",
    extTransform: "none",
    marginLeft: "33px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  },
  "& .rejectedBtn": {
    backgroundColor: 'red',
    color: 'rgba(36, 36, 36, 1)',
    width: "98px",
    height: "28px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  },
  "& .needClarificationBtn": {
    backgroundColor: "#FF7A01",
    color: "rgba(36, 36, 36, 1)",
    width: "200px",
    height: "36px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px"
  },
  "& .downloadBtn": {
    width: "16px",
    height: "16px"
  },
  "& .downloadLargeBtn": {
    width: "24px",
    height: "24px"
  }
})
// Customizable Area End
