import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { ArrowBack, Close } from "@mui/icons-material";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RenewSubscriptionController, {
  Props,
} from "../../../blocks/user-profile-basic/src/RenewMonthlySubscriptionController.web";
import Headers from "../../../components/src/Headers.web";

export default class RenewSubscription extends RenewSubscriptionController {
  constructor(props: Props) {
    super(props);
  }

  renderRenewSubscription() {
    return (
      <div style={{height: "100%"}}>
      <MainContent style={{overflowY:"auto", height: "calc(100% - 71px)",boxSizing:"border-box"}}>
        <ContentArea>
          <PageTop>
            <IconButton edge="start" className="arrowBack">
              <ArrowBack style={{ fontSize: 26 }} onClick={() => this.navigateToScreen("UserProfileBasicWeb")}  data-testId="renewSubscriptionTestId2"/>
            </IconButton>
            <Typography variant="h5" className="headerText">
              Renew Monthly Subscription
            </Typography>
          </PageTop>

          <MainForm>
            <SubscriptionDetails>
              <Box className="boxSize">
                <Typography className="headerTextOne">
                  Renew a Monthly membership
                </Typography>
                <Typography className="headerTextTwo">
                  Simple, transparent pricing that grows with you.
                </Typography>
                <Typography className="headerTextTwo">
                  Try any plan free for 30 days.
                </Typography>
                <SmallLine />
                <FeatureList>
                {this.state.accountSubscription.subscriptionsPlanBenefitId.map((list, index) => {
                    return (
                  <FeatureItem>
                    <CheckCircleIcon
                      style={{ color: "#00FF00", marginRight: "10px" }}
                    />
                    <Typography className="ListItem">
                    {list}
                    </Typography>
                  </FeatureItem>
                     );
                   })}  
                </FeatureList>
                <Typography variant="h5" className="price">
                ${parseInt(this.state.accountSubscription.price)}/mth
                </Typography>
              </Box>
            </SubscriptionDetails>

            <PaymentDetails>
              <ContainerPayOne>
                <Box className="PaymentDetails">Payment details</Box>               
              </ContainerPayOne>

              <ContainerPaytwo>
                <Box className="formBox">
                  <StyledLabel className="InputHeader">Card number</StyledLabel>
                  <StyledTextField
                    fullWidth
                    placeholder="0000 0000 0000 0000"
                    value={this.state.cardNumber}
                    onChange={(e) =>
                      this.handleInputChange("cardNumber", e.target.value)
                    }
                  />
                  <StyledLabel>Cardholder name</StyledLabel>
                  <StyledTextField
                    fullWidth
                    placeholder="Cardholder name"
                    value={this.state.cardholderName}
                    onChange={(e) =>
                      this.handleInputChange("cardholderName", e.target.value)
                    }
                  />
                  <Box display="flex" justifyContent="space-between">
                    <Box width="48%">
                      <StyledLabel>Expiry Date</StyledLabel>
                      <StyledTextField
                        fullWidth
                        placeholder="MM / YYYY"
                        value={this.state.expiryDate}
                        onChange={(e) =>
                          this.handleInputChange("expiryDate", e.target.value)
                        }
                      />
                    </Box>
                    <Box width="48%">
                      <StyledLabel>CVV</StyledLabel>
                      <StyledTextField
                        fullWidth
                        placeholder="3-4 digits"
                        value={this.state.cvv}
                        onChange={(e) =>
                          this.handleInputChange("cvv", e.target.value)
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <StyledLineHorizontal />
                <TotalAmount>
                  <Typography variant="body1">Total Amount</Typography>
                  <Typography variant="h6">
                    ${this.state.totalAmount.toFixed(2)}
                  </Typography>
                </TotalAmount>
              </ContainerPaytwo>

              <ContainerPaythree>
                <Box className="payNowButton">
                  <Button className="ButtonStyle" onClick={this.handlePayment}>
                    Pay now
                  </Button>
                </Box>
              </ContainerPaythree>
            </PaymentDetails>
          </MainForm>
        </ContentArea>
      </MainContent>
      </div>
    );
  }

  render() {
    return (
      <Headers
        sliderButton={this.state.sideBarClose}
        data-testId="renewSubscriptionTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen={this.navigateToScreen}
        isRouteData="RenewSubscription"
      >
        {this.renderRenewSubscription()}
      </Headers>
    );
  }
}

const PaymentDetails = styled(Box)({
  backgroundColor: "rgba(80, 80, 80, 1)",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  minWidth:"200px",
  height:"560px",
  "@media (max-width: 1100px)": {
    width: "86%",
    height:"520px"
  },
  "@media (max-width: 800px)": {
    width: "100%",
    height:"520px"
  },
});

const ContainerPayOne = styled(Box)({
  padding: "30px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid rgb(124, 124, 124, 1)",
  "@media (max-width: 1100px)": {
    padding: "20px",
  },
  "& .PaymentDetails": {   
    height: "28px",
    fontFamily: "Lato",
    color: "rgba(255, 255, 255, 1)",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  "& .closeButton": {
    width: "32px",
    height: "32px",
    color: "white",
  },
});

const ContainerPaytwo = styled(Box)({
  padding: "30px 30px 0px 30px",
  alignItems: "center",
});

const ContainerPaythree = styled(Box)({
  "& .payNowButton": {
    height: "50px",
    backgroundColor: "rgba(0, 255, 0, 1)",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    margin: "0px 15px",
    marginBottom:"10px"
  },
  "& .ButtonStyle": {
    fontFamily: "Lato",
    color: "rgba(36, 36, 36, 1)",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    textTransform: "none",
  },
});

const StyledLineHorizontal = styled(Box)({
  backgroundColor: "rgba(124, 124, 124, 1)",
  width: "100%",
  height: "1px",
  marginBottom: "20px"
});

const SmallLine = styled(Box)({
  backgroundColor: "rgba(124, 124, 124, 1)",
  width: "30px",
  height: "2px",
});

const MainContent = styled(Box)({
  padding: "20px",
  backgroundColor: "rgba(26, 29, 31, 1)",
  fontFamily: "Lato",
  "& .MainForm": {
    Display: "flex",
    flexDirection: "row",
  },
  overflow: "auto"
});

const ContentArea = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const MainForm = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between", // Add this line
  width: "80%",
  "@media (max-width: 1100px)": {
    flexDirection: "column",
  },
});

const PageTop = styled(Box)({
  marginBottom: "20px",
  display: "flex",
  alignItems: "center",
  "& .headerText": {
    fontFamily: "Lato",
    color: "rgba(252, 252, 252, 1)",
    fontSize: "40px",
    lineHeight: "48px",
    fontWeight: 600,
    marginLeft: "10px",
    "@media (max-width: 800px)": {
      fontSize: "30px",
    },
    "@media (max-width: 600px)": {
      fontSize: "25px",
    },
    "@media (max-width: 500px)": {
      fontSize: "20px",
    },
  },
  "& .arrowBack": {
    color: "rgba(211, 211, 211, 1)",
  },
});

const SubscriptionDetails = styled(Box)({
  color: "white",

  "& .boxSize": {
    "@media (max-width: 1100px)": {
      marginBottom:"15px",
    },
  },
  "& .headerTextOne": {
    fontFamily: "Lato",
    color: "rgba(255, 255, 255, 1)",
    fontWeight: 400,
    fontSize: "24px",
    marginBottom: "10px",
    lineHeight: "28.8px",
    letterSpacing: "0.11px",
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
  "& .headerText2": {
    fontFamily: "Lato",
    color: "rgba(211, 211, 211, 1)",
    fontSize: "14px",
    fontWeight: 399,
    lineHeight: "16.8px",
    letterSpacing: "1%",
    marginBottom: "10px",
  },
  "& .MuiTypography-body2": {
    fontFamily: "Lato",
    color: "rgba(211, 211, 211, 1)",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.8px",
  },
  "& .price": {
    fontFamily: "Lato",
    marginTop: "26px",
    fontWeight: 400,
    fontSize: "28px",
    lineHeight: "16.8px",
  },
});

const FeatureList = styled(Box)({
  marginTop: "20px",
});

const FeatureItem = styled(Box)({
  display: "flex",
  marginBottom: "10px",
  fontSize: "14px",
  gap: "15px",
  color: "rgba(255, 255, 255, 1)",
  "& .listItem": {
    fontFamily: "Lato",
    color: "rgba(255, 255, 255, 1)",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    marginBottom: "10px",
  },
});

const StyledTextField = styled(TextField)({
  marginBottom: "20px",
  "& .MuiInputBase-root": {
    color: "white",
    backgroundColor: "rgba(80, 80, 80, 1)",
    border: "1px solid rgba(211, 211, 211, 1)",
    borderRadius: "4px",
    padding: "10px",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "rgba(211, 211, 211, 1))",
  },
});

const StyledLabel = styled(Typography)({
  color: "rgba(211, 211, 211, 1)",
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "20px",
  marginBottom:"5px"
});

const TotalAmount = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "20px",
  marginBottom: "20px",
  "& .MuiTypography-body1": {
    color: "rgba(211, 211, 211, 1)",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
  },
  "& .MuiTypography-h6": {
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
});
