// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import '../../blocks/core/node_modules/react-phone-input-2/lib/style.css'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/InviteEmployee.web";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling.web";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import UserProfileBasicWeb from "../../blocks/user-profile-basic/src/UserProfileBasic";
import UserProfileEditWeb from "../../blocks/user-profile-basic/src/UserProfileEdit";
import UserProfileEmployeeEditWeb from "../../blocks/user-profile-basic/src/UserProfileEmployeeEdit";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Customfeaturescanpersonaldocumentofuser from "../../blocks/customfeaturescanpersonaldocumentofuser/src/Customfeaturescanpersonaldocumentofuser";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Customfeatureintegrationwithkyctoolsumsub from "../../blocks/customfeatureintegrationwithkyctoolsumsub/src/Customfeatureintegrationwithkyctoolsumsub";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import Cfconductkyccheck from "../../blocks/cfconductkyccheck/src/Cfconductkyccheck";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLogin.web"
import EnterCode from "../../blocks/email-account-login/src/EnterCodeScreen.web";
import EnterOtp from "../../blocks/email-account-login/src/EnterOtp.web"
import CustomFormUserList from "../../blocks/customform/src/CustomFormUserList.web" 
import CustomForm from '../../blocks/customform/src/CustomForm';
import PeopleManagement from "../../blocks/customform/src/PeopleManagement.web" 
import EmployeeSignup from '../../blocks/email-account-registration/src/EmployeeSignup.web'
import Header from '../../components/src/Headers.web'

import EmployeHeader from '../../components/src/EmployeHeader.web';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import EmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/EmailAccountRegistration.web"
import SelectRole from '../../blocks/email-account-registration/src/SelectRole.web';
import CreateEmployee from '../../blocks/user-profile-basic/src/CreateEmployee.web';
import EmployeeEditScreen from "../../blocks/accountgroups/src/EmployeeEditScreen.web";
import ViewKycChicklist from "../../blocks/accountgroups/src/ViewKycChicklist.web";
import ChangePassword from '../../blocks/user-profile-basic/src/ChangePassword.web';
import RenewSubscription from '../../blocks/user-profile-basic/src/RenewMonthlySubscription.web'
import BuyCredit from '../../blocks/user-profile-basic/src/BuyCredit.web';

const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
CustomForm:{
  component:CustomForm,
 path:"/CustomForm"},
 Dashboard:{
  component:Dashboard,
 path:"/Dashboard"},
 Header:{
  component:Header,
 path:"/Header"},
 EmployeHeader:{
  component:EmployeHeader,
 path:"/EmployeHeader"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
CustomFormUserList:{
  component:CustomFormUserList,
 path:"/CustomFormUserList"},
 CreateEmployee:{
  component:CreateEmployee,
 path:"/CreateEmployee"},
 PeopleManagement:{
  component:PeopleManagement,
 path:"/PeopleManagement"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
ChangePassword:{
  component:ChangePassword,
 path:"/ChangePassword"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
UserProfileBasicWeb:{
  component:UserProfileBasicWeb,
 path:"/UserProfile"},
 UserProfileEditWeb:{
  component:UserProfileEditWeb,
 path:"/UserProfileEdit"},
 UserProfileEmployeeEditWeb:{
  component:UserProfileEmployeeEditWeb,
 path:"/UserProfileEmployeeEdit"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Customfeaturescanpersonaldocumentofuser:{
 component:Customfeaturescanpersonaldocumentofuser,
path:"/Customfeaturescanpersonaldocumentofuser"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Customfeatureintegrationwithkyctoolsumsub:{
 component:Customfeatureintegrationwithkyctoolsumsub,
path:"/Customfeatureintegrationwithkyctoolsumsub"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Cfconductkyccheck:{
 component:Cfconductkyccheck,
path:"/Cfconductkyccheck"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
 path:'/',
 exact: true},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
EmailAccountLoginWeb:{
  component: EmailAccountLogin,
 path:"/EmailAccountLogin"
},
EnterCode:{
  component: EnterCode,
  path:'/EnterCode'
},
EnterOtp:{
  component: EnterOtp,
  path : '/EnterOtp' 
},
EmployeeSignup:{
  component: EmployeeSignup,
  path:'/EmployeeSignup'
},
CompanySignup :{
  path:'/companysignup',
  component: EmailAccountRegistrationWeb,
  exact: true
},
SelectRole:{
  path:'/selectrole',
  component:SelectRole,
  exact: true
},
EmployeeEditScreen:{
  component:EmployeeEditScreen,
 path:"/EmployeeEditScreen"},
 ViewKycChicklist:{
  component:ViewKycChicklist,
 path:"/ViewKycChicklist"},
// TermsCondition:{
//   component:TermsCondition,
//  path:"/TermsCondition"},

  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  RenewSubscription: {
    component: RenewSubscription,
    path: "/RenewSubscription",
  },
  BuyCredit: {
    component: BuyCredit,
    path: "/BuyCredit",
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
          <ToastContainer hideProgressBar={true} position="top-center"/>
                        </View>
      </BuilderProvider>
          );
  }
}

export default App;