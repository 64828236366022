import React, { Component } from 'react';
import { Dialog, DialogTitle, Box, Typography, Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';

interface FailDailogBoxProps {
  open: boolean;
  onClose: () => void;
  handleSuccessDialogBox: () => void;
}

export default class FailDailogBox extends Component<FailDailogBoxProps> {
  render() {
    const { open, onClose, handleSuccessDialogBox } = this.props;

    return (
      <StyledDialog
        open={open}
        onClose={onClose}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <img onClick={onClose} style={{ padding: "16px 16px 8px 8px", width: "24px", height: "24px", alignSelf: "end", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={require("../src/cross_icon.png")} />
        <StyledDialogTitle id="success-dialog-title">
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <img src={require("../src/image_close.png")} alt="Success Icon" style={{ marginBottom: "16px" }} />
            <Typography style={{ whiteSpace: 'nowrap', color: '#FFF', fontFamily: 'Lato', fontSize: '22px', fontStyle: 'normal', fontWeight: 600, }}>Payment failed. Please verify payment details and try again</Typography>
            <Button variant="text" onClick={handleSuccessDialogBox} style={{ backgroundColor: "#FF1938", marginTop: "30px", width: "160px", padding: '10px', textTransform: "none", color: "#242424", fontFamily: "Lato", fontSize: "16px", fontStyle: "normal", fontWeight: 700 }}> Try Again </Button>
          </Box>
        </StyledDialogTitle>
      </StyledDialog>
    );
  }
}

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    background: "#505050",
    maxHeight: "258px",
    maxWidth: "511px",
    padding: "60px",
  }
});

const StyledDialogTitle = styled(DialogTitle)({
  color: '#ffffff',
  padding: "0px",
  "& .MuiTypography-h6": {
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "28px",
  }
});
