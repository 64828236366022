import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import React from "react";
const navigation = require("react-navigation");
import {getStorageData, removeStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: typeof navigation;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
};

interface CityResponse {
  name: string,
  display_name: string
}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
};

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}
interface User {
  user_list:[];
  total_count: number;
  id: number;
  role_id: string;
  company_name: string;
  email: string;
  full_phone_number: string;
  contact_person_name: string;
  tax_identification_number: string;
  website_link: string; 
  address: string;
  street: string;
  city: string;
  zip_code: string;
  state: string;
  country: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_type: string | null;
  activated: boolean;
  company_unique_id: string;
  address_line_two: string; 
  total_credits: number | null; 
  avaliable_credits: number | null; 
  first_name: string | null; 
  last_name: string | null;
  middle_name: string | null;
  nationality: string | null;
  date_of_birth: string | null;
  passport_number: string | null;
  status: string;
}
export interface Dropdown {
  label: string;
  value: string;
}

interface AppState {
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  nationality: string;
  country: string;
  addressOne: string;
  addressTwo: string;
  state: string;
  city: string;
  zipCode: string;
  passportNumber: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}

export interface FormError {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  dateOfBirth?: string;
  nationality?: string;
  country?: string;
  addressOne?: string;
  addressTwo?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  passportNumber?: string;
  draggedFile?: string;
  passport?: string;
  pan?: string;
  marksheet?: string;
  adhar?: string;
}
export interface SearchData {
  id: number;
  userPic: string;
  name: string;
  dateBirth: string;
  nationality: string;
  country: string;
  address: string;
  passportNo: number;
  documentName: Array<string>;
  documents: Array<string>;
  status: string;
}
interface IEmployeeDataAttributes {
  first_name: string | null;
  last_name: string | null;
  middle_name: string | null;
  date_of_birth: string | null;
  country: string | null;
  country_code: string | null;
  activated: boolean;
  email: string;
  full_phone_number: string;
  created_at: string;
  updated_at: string;
  company_name: string;
  employee_name: string;
  role_id: number | null;
  user_type: string | null;
  company_unique_id: string | null;
  image: string | null;
  nationality: string | null;
  address: string | null;
  address_line_two: string | null;
  state: string | null;
  city: string | null;
  zip_code: string | null;
  passport_number: string | null;
  passport: string | null;
  phone_number: string | null;
}

interface IEmployeeList {
  id: string;
  type: string;
  attributes: IEmployeeDataAttributes;
}

interface IEmployeeData {
  user_list:{
    data: IEmployeeList[];
    total_count:number;
  },
  total_count:number
}
// Customizable Area End

  // Customizable Area Start
export interface S {
  loading: boolean;
  userId: string;
  dataFilter: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  userState: AppState;
  imageModal: boolean;
  imageModalSecond:boolean;
  docType: string;
  inputId: string;
  fileContent: string;
  files: File[];
  selectedImage: string | null;
  imgSizeExceed: boolean;
  draggingIndex: number | null;
  documentTypeModal: boolean;
  documentType: string;
  successModal: boolean;
  draggedFile: File | null;
  passport: File | null;
  pan: File | null;
  marksheet: File | null;
  adhar: File | null;
  userFormError: FormError;
  activeSubmitBtn : boolean;
  anchorEl: HTMLButtonElement | null;
  rejectedText:boolean;
  scanModal: boolean;
  tableData: SearchData[];
  peopleManagementData:IEmployeeList[];
  sideBarClose:boolean;
  role: string;  
  tablesData:User[];
  isDialogOpen:boolean;
  successDialogOpen:boolean;
  deleteId:string;
  token:string;
  addressError: boolean,
  adressTwoError: boolean,
  pageCount: number,
  perPage: number;
  totalDataCount: number;
  searchStatusData: Array<{[key:string]: string}>;
  searchValue: string;
  tableHeaderData: Array<{[key:string]: string}>;
  selectedDate: Date | null;
  open: boolean;
  selectedId: string;
  empPageCount:number;
  totalEmployeeDataCount:number;
  empPerPage:number;
  searchEmpValue: string;
  checkSDK : boolean;
}
  // Customizable Area End

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  fileInputRef: React.RefObject<HTMLInputElement>;
  getUserListApiCallId: string = "";
  getCityAPICallId : string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  getUserDataApiCallId:string="";
  getEmployeeListApiCallId :string="";
  deleteEmployeeApiCallId :string = "";
  initialState: AppState = {
    firstName: '',
    lastName: '',
    middleName: '',
    dateOfBirth: '',
    nationality: '',
    country: '',
    addressOne: '',
    addressTwo: '',
    state: '',
    city: '',
    zipCode: '',
    passportNumber: '',
  };
  initialFormState: FormError = {
    lastName: "",
    middleName: "",
    dateOfBirth: "",
    nationality: "",
    country: "",
    addressOne: "",
    addressTwo: "",
    state: "",
    city: "",
    zipCode: "",
    passportNumber: "",
    draggedFile: "",
    passport: "",
    pan: "",
    marksheet: "",
    adhar: "",
  };
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      dataFilter: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      userState: this.initialState,
      imageModal: false,
      imageModalSecond:false,
      docType: "",
      inputId: "",
      fileContent: '',
      files: [],
      selectedImage: null,
      imgSizeExceed: false,
      draggingIndex: null,
      documentTypeModal: false,
      documentType: "",
      successModal: false,
      draggedFile: null,
      passport: null,
      pan: null,
      marksheet: null,
      adhar: null,
      userFormError: this.initialFormState,
      activeSubmitBtn: false,
      anchorEl: null,
      rejectedText:true,
      tableData: [
        {
          id: 1,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "David Smith",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP ,456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 2,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Aisyah Clara",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP, 456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "pending",
        },
        {
          id: 3,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Anastasia De",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP, 456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 4,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Shirely Wong",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP, 456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "rejected",
        },
        {
          id: 5,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Alexandre",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP, 456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 6,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Jakub Hoxha",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP, 456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "need clarification",
        },
      ],
      peopleManagementData:[],
      scanModal: true,
      sideBarClose :false,
      role: "",
      tableHeaderData: [
        {
          name:  "Name",
          className: "nameTableCell"
        },
        {
          name:  "Date of Birth",
          className: "dobTableCell"
        },
        {
          name:  "Nationality",
          className: "nationalityTableCell"
        },
        {
          name:  "Country",
          className: "countryTableCell"
        },
        {
          name:  "Address",
          className: "addressTableCell"
        },
        {
          name:  "Passport No",
          className: "passportTableCell"
        },
        {
          name:  "Documents",
          className: "documentTableCell"
        },
        {
          name:  "Status",
          className: "statusTableCell"
        }
      ],
      tablesData:[],
      isDialogOpen:false,
      successDialogOpen:false,
      deleteId:"",
      token:"",
      addressError: false,
      adressTwoError: false,
      pageCount: 1,
      perPage: 10,
      totalDataCount: 0,
      searchStatusData: [
        {
          status: "approved",
          className: "searchApprovedStatus"
        },
        {
          status: "pending",
          className: "searchPendingStatus"
        },
        {
          status: "rejected",
          className: "searchRejectedStatus"
        },
        
        {
          status: "need clarification",
          className: "searchClarificationStatus"
        }
      ],
      searchValue: "",
      searchEmpValue:"",
      selectedDate: null,
      open: false,
      selectedId: "",
      empPageCount:1,
      totalEmployeeDataCount:0,
      empPerPage:10,
      checkSDK:false
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
          this.setState({ loading: false });
        } else if (responseJson.errors) {
          toast.error(responseJson.errors,  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
          this.setState({ loading: false });
        } else if (apiRequestCallId == this.getUserListApiCallId) {
          let filterData = responseJson.data.map((item: ResponseJson) => {
            return {
              userId: item.id,
              firstName: item.attributes.first_name,
              lastName: item.attributes.last_name,
              phoneNumber: item.attributes.phone_number,
              email: item.attributes.email,
              organization: item.attributes.organization,
              teamName: item.attributes.team_name,
              userType: item.attributes.i_am,
              rating: item.attributes.stars_rating.toString(),
              gender: item.attributes.gender,
              address: item.attributes.address,
              country: item.attributes.country,
              state: item.attributes.state,
              city: item.attributes.city,
              file: item.attributes.file.file_name,
            };
          });
          this.setState({ filterData,loading:false }, () => {
            this.onChangeHandler(0);
          });
        } else if (apiRequestCallId == this.addUserApiCallId) {
          toast.success("User added successfully!",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
          this.setState({ openDialogName: "" });
          this.getUserList();
        } else if (apiRequestCallId == this.editUserApiCallId) {
          toast.success("User edited successfully!",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
          this.setState({ openDialogName: "", isChange: false });
          this.getUserList();
        }
      }
      if (apiRequestCallId === this.getUserDataApiCallId) {
        this.setState({
          loading: false
        });
            this.userDataAPIResponse(responseJson) 
      }
      this.apiResponseData(apiRequestCallId,responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getUserList();
    this.getUserDataFuntion("");
    this.getEmployeeData("");
    const role = await getStorageData("role");
    const tokenId = await getStorageData("token");
    this.setState({
      role,token:tokenId
    });
  }

  apiResponseData = async (apiRequestCallId : string,responseJson : Array<CityResponse> & IEmployeeData ) =>{
    if (apiRequestCallId === this.getEmployeeListApiCallId) {
      this.employeeDataAPIResponse(responseJson) 
   }
   if(apiRequestCallId === this.deleteEmployeeApiCallId){
     this.deleteEmployAPIResponse(responseJson)
   }
   if(apiRequestCallId === this.getCityAPICallId){
       this.handleCityCheckResponse(responseJson)
   };
  }

  userDataAPIResponse = async(responseJson:User) => {
      if(responseJson.user_list.length > 0){  
       this.setState({ 
        tablesData:responseJson.user_list  ,
        totalDataCount: responseJson.total_count,
        loading:false});
      }else{
        this.setState({loading:false})
      }
  }
  
  handleCityCheckResponse = (responseJson: Array<CityResponse>) => {
    const details = responseJson[0].display_name.split(",");
    this.setState({
      userState: {
        ...this.state.userState,
        city: details[1],
        state: details[details.length - 2],
        country: details[details.length - 1],
        nationality: details[details.length - 1]
      }
    });
  }


  employeeDataAPIResponse = (responseJson:IEmployeeData) => {
    const data = responseJson.user_list.data ? responseJson.user_list.data : []
    this.setState({ peopleManagementData: data ,totalEmployeeDataCount: responseJson.total_count});
  }

  handleLoaderPm =()=>{
    return this.state.peopleManagementData.length > 0 
   }

  getEmployeeData = async(value: unknown) => {
    this.setState({
      loading: true
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEmployeeListApiCallId = requestMessage.messageId;

     requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),  
      `${configJSON.getEmployeeListEndPoint}?&search=${String(value)}&per_page=${this.state.empPerPage}&page=${this.state.empPageCount}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteEmployee = (id: number) => {
    const header = {
      token:this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteEmployeeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteEmployeeApiEndPoint}?id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteEmployeeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleConfirm = () => {
    const toNum=Number(this.state.deleteId)
    this.deleteEmployee(toNum)
  };

  deleteEmployAPIResponse = (responseJson :any) =>{
    if(responseJson.errors){
      toast.error("Employee Does Not Exit!",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
    }else{
      this.setState({ isDialogOpen: false ,anchorEl: null, successDialogOpen:true});
      this.getEmployeeData("")
    }
  }

  handleDialogBox = (id:string) =>{
    this.setState({ isDialogOpen: true,deleteId:id });
  }

  handleCancel = () => {
    this.setState({ isDialogOpen: false ,anchorEl: null,successDialogOpen:false});
  };

  getUserDataFuntion = async(value : unknown) => {
    this.setState({
      loading: true
    });
    const role_type = await getStorageData("role")
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,  
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
     requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDataEndPoint}?role=${role_type}&search=${String(value)}&per_page=${this.state.perPage}&page=${this.state.pageCount}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  onChangeHandler = (page: number) => {
  let { rowsPerPage, filterData } = this.state;
  let dataFilter = filterData;
  const dataLength = dataFilter.length;
  let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
  page = totalPage === page ? page - 1 : page;
  dataFilter = dataFilter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  this.setState({ dataFilter, dataLength, page, loading: false });
  };

  getUserList = () => {
  this.setState({ loading: true });

  const header = {
  "Content-Type": configJSON.getUserListApiContentType,
  token: localStorage.getItem("token"),
  };

  const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getUserListApiCallId = requestMessage.messageId;
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
  );

  requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.getUserListApiEndPoint
  );

  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getUserListApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
  };


  handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    if(name === "cardNumber"){
       this.setState({tablesData:[], searchValue: value as string},()=>{
         this.getUserDataFuntion(value);
       })
    }
    else if (name === "firstName") {
      this.handleFirstName(value);
    }
    else if (name === "lastName") {
      this.handleLastName(value);
    }
    else if (name === "middleName") {
      this.handleMiddleName(value);
    }
    else if (name === "addressOne") {
      this.handleAddressFirst(event);
    }
    else if (name === "addressTwo") {
      this.handleAddressSecond(event);
    }
    else {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState,
          [name as string]: value,
        },
      }), ()=> {
        if(this.state.userState.zipCode){
          if(this.state.userState.zipCode.length === 6){
            this.getCityDetail(this.state.userState.zipCode)
          } else{
            this.setState({
              userState: {
                ...this.state.userState,
                city: "",
                state: "",
                country: "",
                nationality: ""
              }
            })
          }
        } 
      });
    }
  };

  handleChangeEmployee =(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    if(name === "cardNumber"){
       this.setState({tablesData:[], searchEmpValue: value as string},()=>{
         this.getEmployeeData(value);
       })
    }
  }

  handleLastName = (value: unknown) => {
    const regex = configJSON.nameRegex;
    if (regex.test(value as string)) {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState, ["lastName" as string]: value,
        },
      }));
    }
  };

  handleFirstName = (value: unknown) => {
    const regex = configJSON.nameRegex;
    if (regex.test(value as string)) {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState, ["firstName" as string]: value,
        },
      }));
    }
  };

  handleMiddleName = (value: unknown) => {
    const regex = configJSON.nameRegex;
    if (regex.test(value as string)) {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState, ["middleName" as string]: value,
        },
      }));
    }
  };

  handleAddressFirst = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;
    const isDeleting = value.length < this.state.userState.addressOne.length;

    if (isDeleting || value.length < 52) {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState,
          ["addressOne"]: value,
        },
        addressError: false,
      }));
    } else {
      this.setState({ addressError: true });
    }
  };

  handleAddressSecond = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;
    const isDeleting = value.length < this.state.userState.addressTwo.length;

    if (isDeleting || value.length < 52) {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState,
          ["addressTwo"]: value,
        },
        adressTwoError: false,
      }));
    } else {
      this.setState({ adressTwoError: true });
    }
  };

  handleVerify = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { userState } = this.state;
    const formError = this.validate(userState);
    this.setState({ userFormError: formError });
  };

  validate = (values: AppState): FormError => {
    const errors: FormError = {};

    if (!values.firstName) {
      errors.firstName = "Please enter First Name";
    }

    if (!values.lastName) {
      errors.lastName = "Please enter last Name";
    }

    if (!values.dateOfBirth) {
      errors.dateOfBirth = "Please enter date of birth";
    }

    if (!values.nationality) {
      errors.nationality = "Please enter Nationality";
    }
    if (!values.country) {
      errors.country = "Please enter Country Name";
    }
    if (!values.addressOne) {
      errors.addressOne = "Please enter address";
    }
    if (!values.addressTwo) {
      errors.addressTwo = "Please enter address";
    }
    if (!values.state) {
      errors.state = "Please enter State name";
    }
    if (!values.city) {
      errors.city = "Please enter city name";
    }
    if (!values.zipCode) {
      errors.zipCode = "Please enter zip code";
    }
    if (!values.passportNumber) {
      errors.passportNumber = "Please enter passport number";
    }
    if (!this.state.passport) {
      errors.passport = "Please upload Paasport";
    }
    return errors;
  };

  handleModalOpen = (docType: string, inputId: string) => {
    this.setState({ imageModal: true, docType: docType, inputId: inputId }, () => {
    })
  };

  handleModalClose = () => {
    this.setState({ imageModal: false, docType: "" })
  };

  handleDocumentClose = () => {
    this.setState({ documentTypeModal: false })
  };

  handleSuccessModalClose = () => {
    this.setState({ successModal: false, documentTypeModal: false })
  };

  handleAvatarFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      if (files[0].size > 10 * 1024 * 1024) {
        this.setState({ imgSizeExceed: true });
      } else {
        let imgUrl = URL.createObjectURL(files[0]);
        this.setState({ selectedImage: imgUrl, imgSizeExceed: false });
      }
    }
  };

  handleAddressBlur = () => {
    this.setState({
      addressError: false
    })
  };

  handleAddressTwoBlur = () => {
    this.setState({
      adressTwoError: false
    })
  };


  handleButtonClick = () => {
    const fileInput = document.getElementById(this.state.inputId);
    if (fileInput) {
      fileInput.click();
    }
  };

  handlePassportFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      this.setState({
        passport: files[0]
      });
      this.handleFileUpload()
    }
  };

  handleMarksheetFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      this.setState({
        marksheet: files[0]
      });
      this.handleFileUpload()
    }
  };

  handlePanFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      this.setState({
        pan: files[0]
      });
      this.handleFileUpload()
    }
  };

  handleAdharFiles = (selectedFiles: FileList) => {
    this.setState({
      adhar: selectedFiles[0]
    });
    this.handleFileUpload()
  };

  handleAdharFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      this.handleAdharFiles(files);
    }
  };

  onChooseFile = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
      this.setState({ imgSizeExceed: false });
    }
  };

  handleUploadDocument = () => {
    this.setState({ documentType: "", successModal: true });
    setTimeout(() => {
      this.setState({ documentTypeModal: false, successModal: false });
    }, 2000)
  };

  handleDocumentType = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ documentType: event.target.value })
  }

  handleFileUpload() {
    setTimeout(() => {
      this.setState({ documentTypeModal: true, imageModal: false, imageModalSecond: false });
    }, 2000);
  }

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    this.setState({ draggedFile: file });
  }

  handleFileNameLength = (fileName: string | undefined) => {
    if (fileName === undefined) {
      return null
    } else if (fileName.length > 20) {
      const newFileName = fileName.slice(0, 20)
      const fileExtension = fileName.split('.').pop();
      return `${newFileName}.${fileExtension}`
    } else {
      return fileName
    }
  };
  
  handleActiveButton = () => {
    const { userState } = this.state;
    if (!userState.firstName ||   
      !userState.lastName || 
      !userState.dateOfBirth || 
      !userState.addressOne || 
      !userState.addressTwo || 
      !userState.nationality || 
      !userState.country || 
      !userState.state || 
      !userState.city || 
      !userState.zipCode ||
      !userState.passportNumber ||
      !this.state.passport) {
      return "verifyBtn"
    }else{
      return "verifyBtnActive"
    }
  };

  handleLoader =()=>{
   return !this.state.loading && this.state.tablesData.length > 0 
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClickID = (event: React.MouseEvent<HTMLButtonElement>, id:string) => {
    this.setState({ anchorEl: event.currentTarget,selectedId: id });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNeedClarification = () => {
    this.setState({ rejectedText: !this.state.rejectedText, anchorEl: null });
  };

  handleSliderButton = () =>{
    this.setState({sideBarClose:!this.state.sideBarClose});
  }

  kycNavigation = () => {
    const msgTest: Message = new Message(
      getName(MessageEnum.NavigationToKycScreen)
    );
    msgTest.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgTest);
  }

  customFromGoBack=()=>{
    this.props.navigation.goBack("");
  }

  logOutBtn = async () => {
    await removeStorageData("token");
    this.goToLoginScreen();
  }

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToScreen = (screenName:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
        this.send(message)
  };
  
  navigateInviteScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AccountGroups");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToEditEmployee = async(id : string,screenName?:string)=> {
    await setStorageData("eeid",id)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  
  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  getCityDetail = async (zipCode:string) => {
    if(zipCode) {
      this.getCityAPICallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: `https://nominatim.openstreetmap.org/search?postalcode=${zipCode}&format=json`,
      });
    }
  };

  handlePageChange = (event: object, pageCount: number) => {
    this.setState({
      pageCount
    }, () => {
      this.getUserDataFuntion("");
    })
  };

  handleEmployeePageChange = (event: object, empPageCount: number) => {
    this.setState({
      empPageCount
    }, () => {
      this.getEmployeeData("");
    })
  };

  clearData = () => {
    this.setState({ searchValue: "" },()=>{
      this.getUserDataFuntion("");
    })
  };

  clearDataEmployee = () => {
    this.setState({ searchEmpValue: "" },()=>{
      this.getEmployeeData("");
    })
  };

  handleDateChange = (date: Date | null) => {
    const formattedDate = date ? date.toLocaleDateString('en-GB'): '';
    this.setState((prevState: S) => ({
      selectedDate: date,
      open: false,
      userState: {
        ...prevState.userState,
        dateOfBirth: formattedDate,
      },
    }));
  };

  handleOnclick = () => {
    this.setState({
      open: !this.state.open
    });
  };


  CheckSDK = () => {
    this.setState({
      checkSDK: !this.state.checkSDK
    })}
    
  handleRemoveData = () => {
    this.setState({
      userState: this.initialState,
      passport: null,
      selectedDate: null
    });
  };
  // Customizable Area End
}
