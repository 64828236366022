import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { getStorageData,removeStorageData } from "../../../../packages/framework/src/Utilities";
import { runEngine } from "../../../framework/src/RunEngine";


export interface Props {
  navigation: any;
  id: string;
}
export interface PlanAttributes {
  name: string;
  price: string;
  description: string;
  store_credit_id: number;
  subscriptions_plan_benefit_id: string[];
}
export interface MetaData {
  title: string;
  description: string;
}

export interface CreditListAPIResponse {
  data: CreditPlan[];  
  meta: MetaData;      

}
export interface CreditPlan {
  id: string;
  type: string;
  attributes: PlanAttributes;
}

export interface PlanList {
  id: number;
  title: string;
  created_at: string;
  updated_at: string;
}

export interface S {
  features:string[]
  sideBarClose: boolean;
  isDialogOpen:boolean;
  successDialogOpen:boolean;
  failDialogOpen:boolean;
  anchorEl: HTMLButtonElement | null;
  creditPlanlist: CreditPlan[];
  checkCurrentPlan:string|null;
  meta: MetaData | null;
}

export interface SS {
  id: any;
}

export const configJSON = require("./config");

export default class BuyCreditController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
  creditListAPICallId : string = "";
  allPlanListAPICallId : string = "";
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);  
    this.handleSliderButton = this.handleSliderButton.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      sideBarClose: false,  
      features:[],
      isDialogOpen:false,
      successDialogOpen:false,
      failDialogOpen:false,
      anchorEl: null,
      creditPlanlist:[],   
      checkCurrentPlan:null,
      meta:null
    };
  }

  async componentDidMount() {  
    super.componentDidMount(); 
    this.getPlantList();  
    this.getCreditList();
    let currentPlan = await getStorageData("subscription_name");
    this.setState({checkCurrentPlan : currentPlan})
  }  

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );    

      if (apiRequestCallId === this.creditListAPICallId) {
        this.creditListAPIResponse(responseJson) 
      }   
      if (apiRequestCallId === this.allPlanListAPICallId) {
        this.allPlanListAPIResponse(responseJson) 
      }      
    
    }
  }

  creditListAPIResponse = (responseJson: CreditListAPIResponse) => {  
    this.setState({
      creditPlanlist: responseJson.data,  
      meta: responseJson.meta,            
    });
  };
  allPlanListAPIResponse = (responseJson: PlanList[]) => {  
    const featureList = responseJson.map(plan => plan.title);
    this.setState({ features: featureList });
  };

  getCreditList = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.creditListAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CreditListEndpoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getPlantList = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allPlanListAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.planListEndpoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handlePaymentDialogBox = () =>{
    this.setState({ isDialogOpen:true });     
  }

  handleSuccessDialogBox = () =>{
    this.setState({ successDialogOpen:true,failDialogOpen:false, });
  }
  handleFailDialogBox = () =>{
    this.setState({ failDialogOpen:true ,isDialogOpen:false});
  }
  handleCancel = () => {
    this.setState({ anchorEl: null,isDialogOpen:false,failDialogOpen:false,successDialogOpen:false});
  };

  handleSliderButton = () => {
    this.setState({ sideBarClose: !this.state.sideBarClose });
  }

  logOutBtn = async () => {
    await removeStorageData("token");
    this.goToLoginScreen();
  }

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  navigateToScreen = (screenName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
    this.send(message);
  };
}
