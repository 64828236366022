import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { setTimeout } from "timers";
import { toast } from "react-toastify";

// Customizable Area Start
interface userProfilePerticulerCompanyAPi {
  data: {
    id: string;
    type: string;
    attributes: {
    total_credits: string,
    avaliable_credits: string,
    website_link: string,
    company_unique_id: string,
    tax_identification_number: string,
    address: string,
    first_name: string,
    last_name: string,
    middle_name: string,
    user_type: string,
    company_name: string,
    image: string,
    contact_person_name: string,
    employee_name: string,
    designation: string | null,
    subscription_name:string
    };
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  inputData: {
    password: string;
    email: string;  
    tabValue: number;
  };
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  otpData : {otp: string;}
  timer: any;
  count: number;
  disabledBtn: boolean;
  disableLink:boolean;
  isEmailRegistered: boolean;
  isPasswordValid: boolean;
  countTriger:boolean;
  emailError:string;
  Loader:boolean;
  imageLoading: boolean,
  PersonEmail:string;
  countTimer:number;
  showTimer:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  labelTitle: string = "";
  apiOtpCallId: string = "";
  apiResendOtpCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      inputData: {
        email: "",
        password: "",
        tabValue: 0,
      },
      enablePasswordField: true,
      checkedRememberMe: false,
      otpData :{otp: "" },
      timer: 0,
      count: 1,
      disabledBtn: true,
      disableLink:true,
      isEmailRegistered: true,
      isPasswordValid: true,
      countTriger:false,
      emailError: "" ,
      Loader:false,
      imageLoading: false,
      PersonEmail:"",
      countTimer:300,
      showTimer:false
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  async componentDidMount() {
    const rememberedEmail = await getStorageData("rememberEmail");
    const rememberedPassword = await getStorageData("password")
    const rememberedTabValue = await getStorageData("tabValue")

    if (rememberedEmail && rememberedPassword && rememberedTabValue !== null) {  
     this.setState({
         inputData: {
             email: rememberedEmail,
             password: rememberedPassword,
             tabValue: parseInt(rememberedTabValue),  
         },            
         disabledBtn: false,      
         checkedRememberMe: true,
     });
 }

    const PersonEmail =await getStorageData("email");
    this.setState({ PersonEmail: PersonEmail})
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    setInterval(this.timeIncrement,1000);
    const countNo =await getStorageData("count");
    if(countNo >= 3){
      this.setState({
        countTriger:true
      })     
  
    // Customizable Area End
    }}

  async componentDidUpdate(prevProps: any, prevState: { countTriger: boolean }) {
    // Customizable Area Start
    if (!prevState.countTriger && this.state.countTriger) {
        setTimeout(async () => {
            await removeStorageData("count");
            this.setState({ countTriger: false, countTimer: 0});
        }, 60000*5); 
    }
    // Customizable Area End
}
recieveFunc= ()=>{

}
  // Customizable Area Start
  async receive(from: string, message: Message) {
    this.setState({ Loader: true }) 
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

     if(responseJson?.errors){      
      responseJson?.errors?.map((error:any)=>{
        toast.error(error?.failed_login,  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
        toast.error(error?.password,  { hideProgressBar: true, position: "top-center", style: { backgroundColor: '#003111', color: 'white' }  })
        toast.error(error?.otp,  { hideProgressBar: true, position: "top-center", style: { backgroundColor: '#003111', color: 'white' }  })
      })
      this.setState({ Loader: false })
      if(responseJson?.errors[0].slice(0, 16).trim() == "please try after"){
        this.setState({ emailError: (responseJson?.errors[0]).toString()});
      }  
     }
      if (responseJson && responseJson.meta && responseJson.meta.token) {      
        await this.handleApiResponse(apiRequestCallId, responseJson.meta,responseJson);        
      }
    
    }
    this.setState({ Loader: false })
  }
  
  async handleApiResponse(apiRequestCallId: string, meta: any,responseJson:userProfilePerticulerCompanyAPi) {
    switch (apiRequestCallId) {
      case this.apiEmailLoginCallId:
        this.resetPassword(meta)
        break;
      case this.apiOtpCallId :
        await setStorageData("token", meta.token);
        break;
      case this.apiResendOtpCallId:
        await setStorageData("token", meta.token);
        await setStorageData("UserID", responseJson.data.id);
        await setStorageData("image", responseJson.data.attributes.image);
        await setStorageData("first_name", responseJson.data.attributes.first_name);
        await setStorageData("last_name", responseJson.data.attributes.last_name);
        await setStorageData("employee_name", responseJson.data.attributes.employee_name);
        await setStorageData("subscription_name", responseJson.data.attributes.subscription_name==null ? "" : responseJson.data.attributes.subscription_name==null);
        await setStorageData("contact_person_name", responseJson.data.attributes.contact_person_name);
        await setStorageData("designation", responseJson.data.attributes.designation);
        await setStorageData("company_name", responseJson.data.attributes.company_name);
        this.navigateCustomForm();
        break;
    }
  }
//@ts-ignore
  resetPassword = async(data)=>{
    if(data.invited_by_company){
      this.goToLoginScreen();
    }else{
      await this.handleEmailLogin(data);
    }
  }
  
  async handleEmailLogin(meta: any) {
    await setStorageData("token", meta.token);
    await setStorageData("role", meta.role);
    if (meta.role === "company") {
      this.goToCompanyFlow();
    } else if (meta.role === "employee") {
      this.goToEmployeeFlow();
    }
    
    const rememberedEmail = await getStorageData("rememberEmail");
    if (this.state.checkedRememberMe) {
        this.saveRememberMeData(this.state.inputData);
    }
    if (!this.state.checkedRememberMe && rememberedEmail===this.state.inputData.email) {
      this.removeRememberMeData(this.state.inputData);
  }
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EnterCode");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  async saveRememberMeData(inputData: any) {
   await setStorageData('rememberEmail', inputData.email);
   await  setStorageData('password', inputData.password);
   await setStorageData('tabValue', inputData.tabValue.toString());
  }
  async removeRememberMeData(inputData: any) {
    await removeStorageData('rememberEmail');
    await  removeStorageData('password');
    await removeStorageData('tabValue');
   }
  //@ts-ignore
   handleKeyDown = (e) => {
    const { name} = e.target;
    if((name === "email") && !this.state.disabledBtn &&
    ["Enter"].includes(e.key))
    {
      e.preventDefault();
      this.login()
    }
    if((name === "password") && !this.state.disabledBtn &&
    ["Enter"].includes(e.key))
    {
      e.preventDefault();
      this.login()
    }
    if ((name === "otptest") && this.state.otpData.otp.length === 4 && e.key === "Enter") {
      e.preventDefault();
      this.otpLogin();
    }
  };


  login = async() => {  
    {this.setState({ Loader: true })} 
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const data = {
      email: this.state.inputData.email,
      password: this.state.inputData.password,
      role_name:this.state.inputData.tabValue === 0 ? "company":"employee"
    };
    await setStorageData("email",this.state.inputData.email);
    const httpBody = {
      data,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
   
  };

  handleNaviagate=()=>{
    this.setState({ Loader: true }) 
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EnterOtp");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    this.setState({ Loader: false }) 
  }

  handleSelectRoleNavigation=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SelectRole");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ForgotPassword");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  naviagateLogin=async()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    await removeStorageData('count');
  }

  navigateCustomForm=async()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    await removeStorageData('count');
  }

  validateEmail = (email: string) => {
    let error = false;
    const emailRegex = configJSON.emailRegex; 
    if (!emailRegex.test(email)) {
      this.setState({ emailError: "Invalid email" });
      error = true; 
    } else {
      this.setState({ emailError: "" });
      error= false;
    }
    return error;
  };
  handleInputData = (e: any) => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      inputData: {
        ...prevState.inputData,
        [name]: value
      }
    }), () => {
      let error = false;
      if (name === "email") {
        error = this.validateEmail(value); 
      }
      error=error?error:this.state.emailError!=""
     
      const { email, password } = this.state.inputData;

      if (email.length !== 0 && password.length !== 0 && !error) {
        this.setState({ disabledBtn: false });
      } else {
        this.setState({ disabledBtn: true });
      }
    });
   }

  handleCheckboxClick = () => {
    this.setState({
      ...this.state,
      checkedRememberMe: !this.state.checkedRememberMe,
    });
  };

  timeIncrement = () => {
    const state = this.state;
    {
      if (state.timer > 0) {
        this.setState({ timer: state.timer - 1 });
        this.setState({disableLink : true })
      }
      else{
        this.setState({disableLink : false})
      }
      if (state.countTimer > 0 && state.count >= 3 && state.countTriger) {
        this.setState({ countTimer:state.countTimer-1 });
        this.setState({disableLink : true })
      }
    }

  };

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
 
    this.setState({
      inputData: {
        ...this.state.inputData,
        tabValue: newValue,
        email: "",
        password: "",
      },
      checkedRememberMe: false,
    });
    this.setState({ disabledBtn: true });
  };
  getOtp = (e: string) => { 
    this.setState({
      otpData: {
        ...this.state.otpData,
        otp: e,
      },
    });
  };
  otpLogin = async() => {
    this.setState({ Loader: true }) 
    const token =await getStorageData("token")
    if (!token) {
      
    } else {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      'token': token,
    };

    const data = {
      otp: this.state.otpData.otp
    };

    const httpBody = {
      data,
    };

    const otpEndpoint = await getStorageData("role") === "company" ? configJSON.otpApiEndpointCompany : configJSON.otpApiEndpointEmployee
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResendOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      otpEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  this.setState({ Loader: false }) 
  };

  restartTimer = async() => {
    const countNo = await getStorageData("count");
    this.setState({
      showTimer:true,
      timer: 20,
      count: Number(countNo) + 1,
    });
    await setStorageData("count",this.state.count)
    if(countNo >= 3){
      this.setState({
        countTriger:true
      })
    }
    const getRole = await getStorageData("role");
    const getEmail = await getStorageData("email")

    const token = await getStorageData("token")
    if (!token) {
     
    } else {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      'token': token,
    };

    const httpBody = {
      role_name:getRole,
      email: getEmail
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOtpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  };
   handleBackButtonClick = () => {
    this.props.navigation.goBack()
  } 
   
  goToCompanyFlow = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"Header");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
  };

  goToEmployeeFlow = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"EmployeHeader");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
  };
  
  // Customizable Area End
}
