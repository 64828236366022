import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Popover,
  TableContainer,
  MenuItem,
  CircularProgress
} from "@mui/material";
import Pagination from '@mui/material/Pagination';
import DatePicker,{CalendarContainer} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Headers from '../../../components/src/Headers.web';
import { styled } from '@material-ui/styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EmployeHeader from '../../../components/src/EmployeHeader.web';
import { configJSON } from "./DashboardController"
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import { approvedVerification, pendingVerification, rejectedVerification, calanderIcon, clearVerification, pdfIcon, downloadIcon } from "./assets";
import { PagginationBox } from '../../customform/src/CustomFormUserList.web';
import Skeleton from '@material-ui/lab/Skeleton';

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderPaginationCell = () => {
    const { companyListData } = this.state;

    return (
        <TableBody>
            {     
                companyListData?.length > 0
                ? companyListData.map((singleData: any) => (
                    <TableRow key={singleData.id} data-testid="tableRowTestId">
                        <TableCell>
                            <UserName>
                                <img
                                    src={singleData.attributes.image || "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png"}
                                    alt="no image"
                                    className="userImg"
                                />
                                <Typography className="nameTxt">{singleData.attributes.company_name}</Typography>
                            </UserName>
                        </TableCell>
                        <TableCell className="dobTableTxt">{"20/03/2023"}</TableCell>
                        <TableCell className="passportTextTable">{"12345678912"}</TableCell>
                        <TableCell>
                            {
                                ["doc1", "doc2", "doc3", "doc4"].map((document: string) => (
                                    <TableDocWrapper key={document}>
                                        <Box className="singleDoc">
                                            <img src={downloadIcon} className="downloadBtn" />
                                            <img src={pdfIcon} />
                                            <Typography className="documentNameText">{configJSON.passportPdf}</Typography>
                                        </Box>
                                    </TableDocWrapper>
                                ))
                            }
                        </TableCell>
                        <TableCell>
                            <StatusWrapper>
                                {singleData.attributes.status === "approved" || singleData.attributes.status === "rejected" ?
                                    <img src={downloadIcon} className="downloadLargeBtn" /> : null
                                }
                                {this.getStatusButton(singleData.attributes.status || "")}
                            </StatusWrapper>
                        </TableCell>
                    </TableRow>
                ))
                :
                <TableRow>
                    <TableCell colSpan={8} style={customStyles.noDataFoundDiv}>
                        <Box style={customStyles.noDataFoundBox}>
                            <Typography style={customStyles.noDataFoundText}>{configJSON.noDataText}</Typography>
                        </Box>
                    </TableCell>
                </TableRow>
            }
        </TableBody>
    );
}

renderSkeletonLoader=()=>{

  const Arr=[1,2,3,4,5];
  return(
    <TableBody>

      {

      Arr.map(i=>(
      <TableRow>
{
  Arr.map(i=>(
    <TableCell>
    <Skeleton style={{background: ' rgb(113, 108, 108)', borderRadius: '6px'}}  width={200} variant="text" />
    </TableCell>
  ))
}
      </TableRow>
      ))
}

    </TableBody>
  )
}

headerBoxLoader=()=>{
  const Arr=[1,2,3,4];
  return(
    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
   { Arr.map(i=>(
    <Skeleton style={{background: ' rgb(113, 108, 108)', borderRadius: '6px'}} width={350} height={200} variant="text" />
    ))}
    
    </div>
  )
}

  
  renderDashboardForm = () => {


    return (
      <div style={{height:"100%"}}>
      <Wrapper>
        <Typography className="home">{configJSON.homeText}</Typography>

      {this.handleLoader() ?
        <Box className="headerBox">
          
          <Box className="itemBox">
            <Box className="headerIcons">
              <Typography className="itemBoxText">
                <span> <img src={approvedVerification}/></span>{configJSON.approvedVerify}
              </Typography>
              <Typography className="itemBoxCount">{configJSON.numberApprove}</Typography>
            </Box>
          </Box>
          <Box className="itemBox">
            <Box className="headerIcons">
              <Typography className="itemBoxText">
                <span><img src={rejectedVerification}/></span>{configJSON.rejectedverify}
              </Typography>
              <Typography className="itemBoxCount">{configJSON.numberApprove}</Typography>
            </Box> 
          </Box>
          <Box className="itemBox">
            <Box className="headerIcons">
              <Typography className="itemBoxText">
                <span><img src={pendingVerification}/></span>{configJSON.pendingVerify}
              </Typography>
              <Typography className="itemBoxCount">{configJSON.numberApprove}</Typography>
            </Box> 
          </Box>
          <Box className="itemBox lastBox">
            <Box className="headerIcons">
              <Typography className="itemBoxText">
                <span><img src={clearVerification}/></span>{configJSON.clarificationText}
              </Typography>
              <Typography className="itemBoxCount">{configJSON.numberApprove}</Typography>
            </Box>  
          </Box>
        </Box>
        :
        this.headerBoxLoader() 

 } 
        <Box className="contentBox">
          <Typography className="tableHeader">
            {configJSON.uploadVerify}
          </Typography>
         
<div>

  <div style={{ display:'flex', flexDirection:'row',gap:'10px',flexWrap:"wrap"}}>

<div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', border: '1px solid #505050',paddingLeft:'10px', position: 'relative',
        width: '250px'
      }}>

  <img src={calanderIcon} alt="calendarImage"  onClick={this.toggleDatePicker}  />

          <input
          type="text"
          placeholder="Select date range"
          readOnly
          onClick={this.toggleDatePicker} 
          className="input-field"
          data-testId="startDateField"
          value={this.state.startDate ? this.formatDate(this.state.startDate): ''}
          style={{ width: '100%' }}
        />
</div>

<div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', border: '1px solid #505050',paddingLeft:'10px',position: 'relative',
        width: '250px'}}>
<img src={calanderIcon} alt="calendarImage"  onClick={this.toggleDatePicker}  />

<input
          type="text"
          placeholder="Select date range"
          readOnly
          onClick={this.toggleDatePicker} 
          className="input-field"
          data-testId="endDateField"
          value={this.state.endDate ? this.formatDate(this.state.ranges.endDate) : ''}
          style={{ width: '100%' }}
        />
</div>

</div>

{this.state.isPickerVisible && (
  <div   style={{
    position: 'absolute', 
    right: '0px',
    zIndex: 1000,
  }}
  data-testId="daterangepicker"
  
  >

          <DateRangePicker
            onChange={this.handleSelect}
            data-testId="rangeCalendar"
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[this.state.ranges]}
            direction="horizontal"
            className="custom-date-range-picker"

          />
           <div className="date-picker-footer">
              <button className="cancel-button" data-testId="cancelButton" onClick={this.handleCancel}>
                Cancel
              </button>
              <button className="apply-button" data-testId="applyButton" onClick={this.handleApply}>
                Apply
              </button>
            </div>

</div>

        )}
    </div>


<style>
  {`

.custom-date-range-picker .rdrMonthAndYearWrapper select {
  background-color:#505050 ; 
}

.rdrDateDisplayItemActive{
  border:1px solid #A7A7A7;
}
.rdrDateDisplayItem{
  background:#A7A7A7;
}

  .calendarimagediv{
    border: 1px solid #505050;
    width: 23px;
    border-right: none;
    padding: 0px 12px;
    position: relative;
  }

.rdrDateDisplayItemActive input, .rdrDateDisplayItem input{
  background:#505050 !important;
  color:#A7A7A7 !important;
}


.rdrPprevButton i{
  border-color: transparent #A7A7A7 transparent transparent;
}

.rdrNextButton i{
border-color: transparent transparent transparent #A7A7A7;
}

.rdrNextPrevButton{
  background:#505050 !important;
}


.rdrMonthAndYearPickers select{
  color:#A7A7A7 !important;
}

.rdrMonthAndYearWrapper , .rdrDateDisplayWrapper{
  background:#505050 !important;
}

.rdrDateRangePickerWrapper .rdrDateRangePicker {
  position: relative;
}

.rdrDateRangePickerWrapper .rdrDateRangePicker .rdrRangeWrapper {
  // background: #00FF00 !important; 
  
}

.rdrStartEdge, .rdrEndEdge {
  background: #00FF00 !important; 
  
  color: #1A1D1F !important;
}


.rdrDaySelected {
  background: #00FF00 !important; 
  color: #1A1D1F !important;
}


.rdrDateRangePickerWrapper .rdrRangeStartDate,
.rdrDateRangePickerWrapper .rdrRangeEndDate,
.rdrDateRangePickerWrapper .rdrInRange {
 color:#00FF00 !important;
}
.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  display: none; 
}




.rdrStartEdge{
  background-color:#00FF00;
  color:black;
}

.custom-date-range-picker .rdrMonth{
  background:#505050 !important;
}

.custom-date-range-picker .rdrDayNumber span{
  color: #A7A7A7 !important;
}

.date-picker-footer{
  background-color: #505050;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 30px;


}
.date-picker-footer .cancel-button{
  border: 1px solid white;
    color: rgb(0, 255, 0);
    padding: 5px 20px;
    background-color: #505050;
}

.date-picker-footer .apply-button{
  background: rgb(0, 255, 0);
    color: rgb(36, 36, 36);
    border-color: rgb(0, 255, 0);
    padding: 5px 30px;
}

.custom-date-range-picker .rdrDefinedRangesWrapper {
  display: none; 
}

.custom-date-range-picker .rdrDateRangePickerWrapper {
  grid-template-columns: auto !important; 
}



  .input-field{
    background: #1A1D1F;
    color: #7C7C7C;
    padding: 10px 5px;
  border:none;
    position: relative;
  }
  

  .input-field:focus,
.input-field:focus-within {
 
  outline: none; 
}

  `}
</style>
        </Box>
        <StyledTableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell className="nameTableCell">{configJSON.nameText}</TableCell>
                <TableCell className="nameTableCell">{configJSON.kycDate}</TableCell>
                <TableCell className="passportTableCell">{configJSON.passportNumber}</TableCell>
                <TableCell className="documentTableCell">{configJSON.documentText}</TableCell>
                <TableCell className="statusTableCell">{configJSON.statusText}</TableCell>
              </TableRow>
            </TableHead>
            {
              this.handleLoader() ?
              this.renderPaginationCell()  
                :
                this.renderSkeletonLoader()
            }

          </StyledTable>
        </StyledTableContainer>
        {
            this.handleLoader() &&
            <PagginationBox>
                <Pagination data-testId="paginationTestId" count={Math.ceil(this.state.totalItems / 10)} variant="outlined" shape="rounded" onChange={this.handlePageChange} page={this.state.currentPage} />
            </PagginationBox>
        }
      </Wrapper>
      </div>
    )
  }

  getStatusButton = (status: string) => {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const rejectText = this.state.rejectedText;
    switch (status) {
      case 'approved':
        return (
          <Button className="approvedBtn">
            {configJSON.approvedText}
          </Button>
        );
      case 'pending':
        return (
          <Button className="pendingBtn">
            {configJSON.pendingText}
          </Button>
        );
      case 'rejected':
        return (
          <>
            <Button
              className={rejectText ? "rejectedBtn" : "needClarificationBtn"}
              data-testId="rejectTestId"
              onClick={this.handleClick}
              endIcon={<ExpandMoreIcon />}
            >
              {rejectText ? "Rejected" : "Need Clarification"}
            </Button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              data-testId="closeAnchorTestId"
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  marginTop: "10px",
                  backgroundColor: !this.state.rejectedText ? "red" : "#FF7A01",
                  color: "black",

                },
              }}
            >
              <MenuItem className="needClaritiy" data-testId="needClarificationTestId" onClick={this.handleNeedClarification}> {!rejectText ? "Rejected" : "Need Clarification"}
              </MenuItem>
            </Popover>
          </>
        );
      default:
        return null;
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
      {
        this.state.role === "company" ? <Headers sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn} 
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen = {this.goToScreen}
        isRouteData="Dashboard"
        >
          {this.renderDashboardForm()}
      </Headers> 
      :

      <EmployeHeader
      sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn} 
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen = {this.goToScreen}
        isRouteData="Dashboard"
      >
        {this.renderDashboardForm()}
      </EmployeHeader>
      }
    </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const Wrapper = styled(Box)({
  background: "#242424",
  padding: "12px 24px",
  boxSizing: "border-box",
  height: "calc(100% - 71px)",
  overflowY:"auto",
  "& .home": {
    fontWeight: 600,
    color: "rgba(252,252,252,1)",
    fontSize: "40px",
    lineHeight: "48px",
    letterSpacing: "-2%",
    fontFamily: "Lato",
  },
  "& .headerBox": {
    marginTop: "18px",
    color: "rgba(252,252,252,1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap:"20px",
    flexWrap:"wrap"
  },
  "& .itemBox": {
    background: "rgba(26,29,31,1)",
    padding: "24px 19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap:"15px",
    width:"200px",
    "@media (max-width: 1400px)": {
      "& .itemBox": {
        width:"250px",
      },
    },
  },
  "@media (max-width: 1600px)": {
    "& .itemBox": {
      height:"105px"
    },
  },
  "& .headerIcons":{
    width:"100%",
    margin:"auto",
    padding:"0px 16px"
  },
  "& .lastBox": {
    // width: "240px",
  },
  "& .itemBoxText": {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Lato",
    latterSpacing: "-1%",
    display:"flex",
    alignItems:"center",
    gap:"16px",
    paddingBottom:"12px"
  },
  "& .loadMoreBtnBox": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px"
  },
  "& .loadMoreBtn": {
    textTransform: "none",
    color: "white",
    height: "48px",
    border: "2px solid rgba(39, 43, 48, 1)",
    fontSize: "15px",
    fontWeight: 700,
    borderRadius: "12px",
    fontFamily: 'Lato',
  },
  "& .itemBoxCount": {
    fontWeight: 500,
    fontSize: "30px",
    fontFamily: 'Lato',
  },
  "& .itemBoxIcon": {},
  "& .contentBox": {
    marginTop: "31px",
    background: "rgba(26,29,31,1)",
    color: "rgba(252,252,252,1)",
    padding: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap:"wrap"
  },
  "& .tableHeader": {
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "40px",
    latterSpacing: "-2%",
  },
  dialogFirstInputField: {
    width: "100%", borderRadius: '8px',
    fontWeight: 600,
    border: '0.5px solid #F1F5F9',
    background: '#F1F5F9',
  },
  "& .datePicker": {
    border: "2px solid #808080",
    width: "180px",
    height: "44px",
    padding: "10px 18px",
    color: "#808080",
  },
  "& .calnderStyle": {
    border: "2px solid rgba(80, 80, 80, 1)",
    width: "180px",
    height: "42px",
    color: "rgba(80, 80, 80, 1)",
    marginTop: "0px",
    paddingBottom: "10px"
  },
  "& .datePickers": {
    display: "flex",
    gap: "10px",
  },
  "& .placeholder-white::placeholder": {
    color: "white",
    "& .MuiInputBase-input":
    {
      paddingLeft: "36px"
    }
  },
});

const customStyles = {
  noDataFoundDiv: {
    textAlign: 'center'
  } as React.CSSProperties,
  noDataFoundBox: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 0px",
  },
  noDataFoundText: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Open Sans",
    color: "#390689"
  },
}

const StyledTableContainer = styled(TableContainer)({
  width: "Fixed (1,264px)px",
  height: "Fixed (41px)px",
  gap: "24px",
  opacity: "0px",

  "& .userImg": {
    width: "48px",
    height: "48px",
    borderRadius: "48px"
  }
});
const DatePickerContainer = styled(DatePicker)({

  '& .react-datepicker__header': {
    backgroundColor: '#242424', // Background color of the popup header
  },
  '& .react-datepicker__month-container': {
    backgroundColor: '#242424', // Background color of the entire date picker popup
  },
  '& .react-datepicker__day--selected': {
    backgroundColor: '#ff6347 !important', // Background color of the selected date
    color: 'white', // Text color of the selected date
  },
  '& .react-datepicker__day:hover': {
    backgroundColor: '#ff6347', // Background color when hovering over a date
    color: 'white', // Text color on hover
  },


})



const TableDocWrapper = styled(Box)({
  "& .singleDoc": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .downloadBtn": {
    width: "16px",
    height: "16px"
  }
});

const StyledTable = styled(Table)({
  backgroundColor: "#18191b",
  "& .MuiTableCell-root": {
    verticalAlign: "top",
    borderBottom: "1px solid #272B30",
  },
  "& .MuiTableCell-head": {
    color: "#fff",
    width: "max-content",
  },
  "& .MuiTableCell-body": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600
  },
  "& .nameTxt": {
    color: "rgba(252, 252, 252, 1)",
    fontWeight: 700,
    fontFamily: 'Lato',
    height: "24px",
    width: "114px",
    fontSize: "15px"
  },
  "& .dobTableTxt": {
    fontWeight: 600,
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontFamily: 'Lato',
    height: "81px",
    width: "24px"
  },
  "& .countryTableText": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    width: "84px",
    height: "24px",
    fontFamily: 'Lato',
    whiteSpace: "nowrap"
  },
  "& .passportTextTable": {
    width: "81px",
    height: "24px",
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontFamily: 'Lato',
    fontWeight: 600,
  },
  "& .nationalityText": {
    width: "80px",
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Lato',
    height: "24px"
  },
  "& .addressText": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Lato',
    width: "249px",
    height: "48px"
  },
  "& .lato": {
    fontFamily: 'Lato'
  },
  "& .dobTableCell": {
    width: "78px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600,
    whiteSpace: "nowrap"

  },
  "& .nameTableCell": {
    width: "36px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .nationalityTableCell": {
    color: 'rgba(211, 211, 211, 1)',
    width: "68px",
    fontFamily: 'Lato',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .countryTableCell": {
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    width: "50px",
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .addressTableCell": {
    width: "50px",
    color: 'rgba(211, 211, 211, 1)',
    fontFamily: 'Lato',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .passportTableCell": {
    width: "106px",
    fontFamily: 'Lato',
    fontWeight: 600,
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
  },
  "& .documentTableCell": {
    gap: '0px',
    width: "70px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .statusTableCell": {
    width: "70px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    fontWeight: 600,
    height: '16px',
    gap: '0px',
    opacity: "0px",
  },
  "& .documentNameText": {
    fontFamily: 'Lato',
    fontWeight: 600,
    color: "rgba(111, 118, 126, 1)"
  }
});

const UserName = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px"
});

const StatusWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "& .approvedBtn": {
    backgroundColor: '#41d613',
    color: 'rgba(36, 36, 36, 1)',
    width: "76px",
    height: "28px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  },
  "& .pendingBtn": {
    backgroundColor: 'yellow',
    color: 'rgba(36, 36, 36, 1)',
    width: "80px",
    height: "28px",
    extTransform: "none",
    marginLeft: "33px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  },
  "& .rejectedBtn": {
    backgroundColor: 'red',
    color: 'rgba(36, 36, 36, 1)',
    width: "98px",
    height: "28px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  },
  "& .needClarificationBtn": {
    backgroundColor: "#FF7A01",
    color: "rgba(36, 36, 36, 1)",
    width: "200px",
    height: "36px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px"
  },
  "& .downloadBtn": {
    width: "16px",
    height: "16px"
  },
  "& .downloadLargeBtn": {
    width: "24px",
    height: "24px"
  }
})
// Customizable Area End
