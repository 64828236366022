Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.getUserDataEndPoint = "bx_block_customfeatureintegrationwithkyctoolsumsub/kyc_checks";
exports.getEmployeeListEndPoint = "account_block/employee_list";

exports.deleteEmployeeApiMethod = "DELETE";
exports.deleteEmployeeApiEndPoint = "account_block/delete_employee"

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";


exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";
exports.token="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod="PUT";
exports.fetchFormDataMethod="GET";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity="City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;
exports.userName="User's Information";
exports.firstName= "First name*";
exports.middleNames = "Middle name";
exports.lastName = "Last name*";
exports.birthDate = "Date of birth*";
exports.nationalityName = "Nationality*";
exports.selectCountry = "Select Country*";
exports.adressFirst = "Address line 1*";
exports.secondAdress = "Address line 2*";
exports.stateSelect = "Select State*";
exports.citySelect = "Select City*";
exports.zipCode = "Zip code*";
exports.passportNumber = "Passport Number*";
exports.uploadPassport = "Upload Passport";
exports.uploadText = "Upload JPEG, PNG & PDF up to 10MB";
exports.uploadTextTwo = "Upload JPEG, PNG up to 10MB";
exports.uploadDocument= "Upload document";
exports.dragText = "Drag and drop the files here";
exports.browserBtn = "Browse";
exports.documentType = "Enter Document Type";
exports.cancelBtn = "cancel";
exports.uploadBtn = "Upload";
exports.successMessage = "User document successfully uploaded";
exports.uploadButton = "Upload Picture";
exports.verifyText = "verify";
exports.dateOfBirth = "Date of birth*"
exports.userInformation = "User's Verification";
exports.verifyText = "Verify";
exports.nameText = "Name";
exports.dateOfBirth = "Date of Birth*";
exports.nationality = "Nationality";
exports.country = "Country";
exports.address = "Address";
exports.passportNumber = "Passport No";
exports.documents = "Documents";
exports.userText = "Status";
exports.approvedText = "Approved";
exports.pendingText = "Pending";
exports.rejectedText = "Rejected";
exports.clearificationText = "Need clarification";
exports.nationalityText = "Select nationality";
exports.countryText = "Select Country";
exports.stateText = "Select State";
exports.cityText = "Select City";
exports.loadText = "Load more";
exports.emailId = "Email ID";
exports.contactName = "Contact Name";
exports.phoneNumber = "Phone Number";
exports.action = "Action";
exports.employeeInformation = "Employee Information";
exports.createEmployee =  "+  Create Employee";
exports.popUpData = "Are you sure you want to delete this Employee?";
exports.sucessMsg = "Selected Employee Deleted Successfully";
exports.nameRegex = /^[a-zA-Z]*$/;
exports.dataNotText = "Data Not Found"

// Customizable Area End