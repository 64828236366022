// Customizable Area Start
export const scaleKYC = require("../assets/image_logo.png");
export const heroImage = require("../assets/hero.png");
export const heroArrow = require("../assets/greenArrow.svg");
export const lineImage = require("../assets/image_analytics_24px.svg");
export const digitalImage = require("../assets/image_digital_24px.svg");
export const supportImage = require("../assets/image_kyc_24px (1).svg");
export const scaleKycImage = require("../assets/group_Wrapper.png");
export const globeImage = require("../assets/image_ (1).png");
export const readMoreArrow = require("../assets/image_ (2).png");
export const twitterIcon = require("../assets/twitter.svg");
export const instgramIcon = require("../assets/instagram.svg");
export const linkdinImage = require("../assets/linkedin.svg");
export const arrowBlack = require("../assets/blackArrow.png")
// Customizable Area End