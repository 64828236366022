import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import {  ChangeEvent } from 'react';
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  details: string;
  comments: string;
  sideBarClose:boolean;
  isButtonDisabled:boolean;
  nameError: string | null;
  emailError: string | null;
  token:string;
  role:string;
  successDialogOpen:boolean;
  anchorEl: HTMLButtonElement | null;
  // Customizable Area End
}
  // Customizable Area Start
interface SS {
  id: any;
}
// Customizable Area End

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      details: "",
      comments: "",
      sideBarClose:false,
      isButtonDisabled:true,
      nameError: "",
      emailError:"",
      token:"",
      role:"",
      successDialogOpen:false,
      anchorEl: null
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getToken();
    const roleId = await getStorageData("role"); 
    const tokenId = await getStorageData("token");
    this.setState({
      token:tokenId,role:roleId
    });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    if (apiRequestCallId === this.addContactApiCallId) {
      this.handleApiResponse(responseJson)
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleCancel = () => {
    this.setState({ anchorEl: null,successDialogOpen:false});
  };

  handleApiResponse = (response:{
    errors?: { token: string }[];
    message?: string;
    error?:string;
  })=> {
    if (response.errors && response.errors.length > 0) {
      const error = response.errors[0].token;
      if (error === "Token has Expired") {
        toast.error("Token has expired. Please login again.",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} );
      }
    }else if (response.error) {
      toast.error(response.error, { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} );
    }else if(response.message) {
      this.setState({ anchorEl: null,successDialogOpen:true, name: "",email: "",details: "",comments: "",isButtonDisabled:true});
    }
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
  
    if (name === 'name') {
      const containsNonAlpha = /[^A-Za-z\s]/.test(value);
      const exceedsMaxLength = value.length > 52;
      if (containsNonAlpha || exceedsMaxLength) {
        this.setState({
          nameError: containsNonAlpha ? "Name must only contain letters and spaces." : exceedsMaxLength ? "Name must be 52 characters or less." : null
        });
        this.setState({ isButtonDisabled : true});
        return;
      } else {
        this.setState({ nameError: null });
      }
    }
  
    if (name === 'email') {
      const emailPattern = configJSON.emailRegex;
      const isEmailValid = emailPattern.test(value);
      if (!isEmailValid) {
        this.setState({ emailError: "Enter a valid email address." });
        this.setState({ isButtonDisabled : true});
      } else {
        this.setState({ emailError: null });
      }
    }
   //@ts-ignore
    this.setState({ [name]: value } as Pick<State, keyof State>, this.validateForm);
  };
  
  validateForm = (): void => {
    const { name, comments, email, details } = this.state;
    const emailPattern = configJSON.emailRegex;
    const isEmailValid = emailPattern.test(email);
    const isButtonDisabled = !name || !comments || !isEmailValid|| !details;
    this.setState({ isButtonDisabled });
  };

  handleSliderButton = () =>{
    this.setState({sideBarClose:!this.state.sideBarClose});
  }

  logOutBtn = async () => {
    await removeStorageData("token");
    this.goToLoginScreen();
  }

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToScreen = (screenName:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
        this.send(message)
  };

  contactUsApi = () => {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          subject: this.state.details.trim(),
          details: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sendContactUsApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  // Customizable Area End
}
