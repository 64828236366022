export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/LogoMain.svg");
export const mainImage = require("../assets/group_right image.png");
export const laptopImg = require("../assets/laptopLogos.svg");
export const forwordArrow = require("../assets/arrow_forward.png");
export const userImg = require("../assets/employeeCircleLogos.svg");
export const SelectSubscriptionImg = require("../assets/Frame 18.png");
export const FeaturedIcon = require("../assets/Featured icon.png");
export const check = require("../assets/Check icon.png")
export const uncheck = require("../assets/image_(5).png");
export const checked = require ("../assets/checked.png");
export const arrowIconBack = require ("../assets/arrowIconBack.svg");
export const LaptopIMGBlack = require ("../assets/LaptopImg.svg");
export const EmployeeIMGBlack = require ("../assets/EmpImage.svg");
export const RightBlackArrow = require ("../assets/RightBlack.svg");