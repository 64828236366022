import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start

import {getStorageData, removeStorageData } from "../../../../packages/framework/src/Utilities";
export const configJSON = require("./config.js");

export interface SearchData {
  id: number;
  userPic:string;
  name: string;
  kycCheck: string;
  passportNo:number;
  documents:Array<string>;
  documentName:Array<string>;
  status:string;
}

export interface DateRange {
  startDate: Date | null;
  endDate: Date | null;   
  key: string;           
}

export interface CompanyListData {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    company_name: string;
    company_unique_id: string;
    email: string;
    full_phone_number: string;
    contact_person_name: string;
    tax_identification_number: string;
    website_link: string;
    address: string;
    street: string;
    city: string;
    zip_code: string;
    state: string;
    country: string;
    role_id: string;
    user_type: string;
    address_line_two: string | null;
    image: string | null;
    total_credits: number | null;
    available_credits: number | null;
    first_name: string | null;
    last_name: string | null;
    middle_name: string | null;
    nationality: string | null;
    date_of_birth: string | null;
    status: string | null;
    passport_number: string | null;
    passport: string | null;
    stripe_customer_id: string | null;
    is_subscribed: boolean | null;
    subscription_name: string | null;
    employees: any[];
  };
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  sideBarClose: boolean;
  tablesData:SearchData[];
  rejectedText:boolean;
  role: string;
  fromDate: Date | null;
  toDate:Date | null;
  anchorEl: HTMLButtonElement | null;
  companyListData : CompanyListData[];
  startDate:Date | null;
  endDate:Date | null;
  ranges:any;
  isPickerVisible:boolean;
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getCompanyListApiCallId :string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      sideBarClose: false,
      anchorEl:null,
      rejectedText:true,
      role: "",
      fromDate: null,
      toDate:null,
      startDate: null,
      endDate: null,
      ranges: 
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      isPickerVisible: false,
      tablesData: [
        {
          id: 1,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "David Smith",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 2,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Aisyah Clara",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "pending",
        },
        {
          id: 3,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Anastasia De",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 4,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Shirely Wong",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "rejected",
        },
      ],
      companyListData: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getCompanyData("");
    this.setState({
      endDate:this.state.startDate
    })
    const role = await getStorageData("role");
    const tokenId = await getStorageData("token");
    this.setState({
      role,token:tokenId
    });
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );
    
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
      this.apiResponseData(webApiRequestCallId,webResponseJson)
    }
    // Customizable Area End
  }
  // Customizable Area Start
  

  formatDate = (date:Date ) => {
    
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' }); 
    const year = date.getFullYear().toString().slice(-2); 
    const weekday = date.toLocaleString('en-US', { weekday: 'long' }); 
  
    return `${day} ${month}’${year} ${weekday}`;
  };

  handleApply = () => {
  
    this.setState({
      startDate:this.state.ranges.startDate,
      endDate:this.state.ranges.endDate,
      isPickerVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
     
      isPickerVisible: false,
      startDate:null,
      endDate:null
    });
  };

 

  handleSelect = (d:any) => {
 
    this.setState({ ranges: d?.selection });
      
  };

  toggleDatePicker = () => {
    this.setState((prevState) => ({ isPickerVisible: !prevState.isPickerVisible }));
  };



  handleSliderButton = () =>{
    this.setState({sideBarClose:!this.state.sideBarClose});
  }
  logOutBtn = async () => {
    await removeStorageData("token");
    this.goToLoginScreen();
  }

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  navigateToScreen = (screenName:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
        this.send(message)
  };
  handleLoader =()=>{
    return !this.state.loading && this.state.tablesData.length > 0
   };

   handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNeedClarification = () => {
    this.setState({ rejectedText: !this.state.rejectedText, anchorEl: null });
  };

  goToScreen = (screenName:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
        this.send(message)
  };

  handleDateChange = (date: Date | null) => {
    this.setState({ fromDate: date, toDate: null });
  };

  handleMaxDate = () => {
    const { fromDate } = this.state;
    const currentDate = new Date();
    const maxDate = fromDate ? new Date(Math.min(currentDate.getTime(), new Date(fromDate).getTime() + 720 * 24 * 60 * 60 * 1000)) : currentDate;
    return maxDate
  };

  toDateInput = (date :  Date | null) => {
    this.setState({toDate: date});
  };

  getCompanyData = (value : unknown) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyListApiCallId = requestMessage.messageId;

     requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companyListGetUrl}?per_page=${this.state.itemsPerPage}&page=${this.state.currentPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  apiResponseData = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getCompanyListApiCallId) {
        const data = responseJson.user_list?.data;
        this.setState({ companyListData: data, totalItems: responseJson.total_count,});
    }
  }

  handlePageChange = (event: object, currentPage: number) => {
    this.setState({
      currentPage
    }, () => {
      this.getCompanyData("");
    })
  };

  // Customizable Area End
}
