import { Box,Dialog, DialogTitle, Typography, Button } from '@material-ui/core'
import React, { Component } from 'react'
import { styled } from '@material-ui/styles';
import SumsubWebSdk from '@sumsub/websdk-react';


type MyProps = {
  accessToken: | string;
  onClose: () => void;
}

export default class KycWbSDK extends Component<MyProps> {

  state = {
    accessToken: "token", 
  };

  handleAccessTokenExpiration = async () => {   
    const newAccessToken = this.state.accessToken
    return newAccessToken;
  };

  handleOnMessage = (message : string) => {
 
  };

  handleOnError = (error : string) => {
  
  };

  render() {
    const { accessToken, } = this.state;
    const { onClose  } = this.props;
    return (
      <>    
      <StyledDialog
        open={true}
        onClick={onClose}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <img onClick={onClose} style={{ padding: "16px 16px 8px 8px", width: "24px", height: "24px", alignSelf: "end", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={require("../src/cross_icon.png")} />
       
        <SumsubWebSdk
            accessToken={accessToken}
            expirationHandler={this.handleAccessTokenExpiration}
            config={{
              document: {
                allowedTypes: ['PASSPORT'],
              },
              lang: 'en',
              email: 'john.smith@sumsub.com',
              phone: '+449112081223',
              theme: 'dark',
            }}
            options={{ addViewportTag: false, adaptIframeHeight: true }}
            onMessage={this.handleOnMessage}
            onError={this.handleOnError}
          />
   
      </StyledDialog>
      </>
    )
  }
}

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    background: "#505050",
    width:"100%"
  }
});

