import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Typography,
  styled,
} from "@material-ui/core";
import {arrowIconBack, logo} from './asserts';
import StripePayments from "../../../components/src/StripeWrapper.web";
import SuccessDailogBoxProfile from '../../../components/src/SuccessDailogBoxProfile.web';
import FailDailogBox from '../../../components/src/FailDailogBox.web';
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { role, checked } = this.state;
    const isCompany = role === "company";

    return (
      <TermsConditionsStyles 
      style={{ width: "100%", height: "100%" }}
      >
        <Box>
            <Grid item xl={12} xs={12} lg={12} sm={12}>
              <Box
                style={{
                  borderBottom: "1px solid #505050",
                  width: "100%",
                  backgroundColor: "#242424",             
                  
                }}
              >
                <img
                  alt="logo"
                  src={logo}
                  style={{
                    paddingRight: "2%",
                    marginLeft: "auto",
                    display: "flex",  
                    cursor: "pointer"               
                  }}
                />
              </Box>
            </Grid>
        </Box>
        <Box className="headerFiled">
          <Grid item xl={12} xs={12} lg={12} sm={12}>
            <Box className="headerWrapper">
              <img
                  alt="backButton"
                  src={arrowIconBack}
                  onClick={this.handleButtonClick}
                                  />
              <Typography className="headerName">Terms & Conditions</Typography>  
            </Box>
          </Grid>
        </Box>
        <Box style={{background:'#242424'}}>
          <Grid item xl={12} xs={12} lg={12} sm={12}>
            <Box className="contentWrapper">
              <Typography className="condTitle">
              1.{this.state.termsConstionsList.title}
              </Typography>
                <Typography className="termsContent">
                  1.1. {this.state.termsConstionsList.description}
                </Typography>
                <Typography className="termsContent">
                  1.2. Submission of the Application Form does not guarantee approval or accreditation within the Scale Compliance.
                </Typography>
              <Typography className="condTitle">
                2. Limitation of liability
              </Typography>
                <Typography className="termsContent">
                  2.1. You must provide accurate and complete information in the Application Form.
                </Typography>
                <Typography className="termsContent">
                  2.2. Submission of the Application Form does not guarantee approval or accreditation within the Scale Compliance. If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
                </Typography>
              <Typography className="condTitle">
                3. Confidentiality
              </Typography>
                <Typography className="termsContent">
                  3.1. You must provide accurate and complete information in the Application Form. Company Name is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.
                </Typography>
                <Typography className="termsContent">
                  3.2. Submission of the Application Form does not guarantee approval or accreditation within the Scale Compliance.
                </Typography>
              <Typography className="condTitle">
                4. Compliance with Regulations
              </Typography>
                <Typography className="termsContent">
                  4.1. You must provide accurate and complete information in the Application Form.
                </Typography>
                <Typography className="termsContent">
                  4.2. Submission of the Application Form does not guarantee approval or accreditation within the Scale Compliance. You are granted limited license only for purposes of viewing the material contained on this Website.
                </Typography>
              <Typography className="condTitle">
                5. Confidentiality
              </Typography>
                <Typography className="termsContent">
                  5.1. You must provide accurate and complete information in the Application Form.
                </Typography>
                <Typography className="termsContent">
                  5.2. Submission of the Application Form does not guarantee approval or accreditation within the Scale Compliance.
                </Typography>
              <Typography className="condTitle">
                6. Communication
              </Typography>
                <Typography className="termsContent">
                  6.1. You must provide accurate and complete information in the Application Form.
                </Typography>
                <Typography className="termsContent">
                  6.2. Submission of the Application Form does not guarantee approval or accreditation within the Scale Compliance.
                </Typography>
              <Typography className="condTitle">
                7. Changes to terms
              </Typography>
                <Typography className="termsContent">
                  7.1. You must provide accurate and complete information in the Application Form.
                </Typography>
                <Typography className="termsContent">
                  7.2. Submission of the Application Form does not guarantee approval or accreditation within the Scale Compliance.
                </Typography>
              <Typography className="condTitle">
                8. Termination
              </Typography>
                <Typography className="termsContent">
                  8.1. You must provide accurate and complete information in the Application Form. Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity.
                </Typography>
                <Typography className="termsContent">
                  8.2. Submission of the Application Form does not guarantee approval or accreditation within the Scale Compliance. These Terms constitute the entire agreement between Company Name and you in relation to your use of this Website, and supersede all prior agreements and understandings.
                </Typography> 
                <Box style={{marginTop:'40px'}}>  
                                   
                        <Checkbox                        
                          className="checkBox"                         
                          checked={this.state.checked}
                          onClick={this.handleCheckboxClick}                 
                       />
                        <label
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '22px',
                            textAlign: 'left',
                            color: '#D3D3D3',                       
                          }}
                        >
                          I agree to the Terms of Service and Privacy Policy.
                        </label>
                      </Box>  

          <br/>
          {isCompany ? <Button data-testId="BuyCreditToggleTest" className="customBtn" variant="contained" disabled={!this.state.checked} style={
                      !this.state.checked
                        ? { backgroundColor: "#7C7C7C" }
                        : { backgroundColor: "#00FF00" }
                    }
                    onClick={this.handlePaymentDialogBox}
                    >
                      <Typography className="btntypo">
                         {configJSON.comText }
                      </Typography>
          </Button> : <Button className="customBtn" variant="contained" disabled={!this.state.checked} style={
                      !this.state.checked
                        ? { backgroundColor: "#7C7C7C" }
                        : { backgroundColor: "#00FF00" }
                    }
                    onClick={this.handleButtonClick}
                    >
                      <Typography className="btntypo">
                        { configJSON.empText}
                      </Typography>
          </Button>}
            </Box>
          </Grid>
        </Box>
        <SuccessDailogBoxProfile
         data-testId="confirmDialog_test"
         open={this.state.successDialogOpen}
         onClose={this.handleCancel}   
        />
        <FailDailogBox
         data-testId="confirmDialog_test2"
         open={this.state.failDialogOpen}
         onClose={this.handleCancel}
         handleSuccessDialogBox={this.handleSuccessDialogBox}
        />
        <StripePayments
          data-testId="confirmDialog_test3"
          open={this.state.isDialogOpen}
          onClose={this.handleCancel} 
          payAmount={this.state.paymentPrice}
          handleFailDialogBox={this.handleFailDialogBox}
          handleSuccessDialogBox={this.handleSuccessDialogBox}
        />
      </TermsConditionsStyles>
    )
        // Customizable Area End
  }
}

// Customizable Area Start
const TermsConditionsStyles = styled(Box)({
  "& .headerFiled":{
    height: '216px',
    top: '80px',
    gap: '10px',
    opacity: '0px',
    background: '#FFFFFF',
  },
  "& .headerName":{
    fontFamily: 'Lato',
    fontSize: '48px',
    fontWeight: 700,
    lineheight: '56px',
    letterSpacing: '-0.015em',
    textAlign: 'left',
    color: '#00FF00'

  },
  "& .headerWrapper":{
    display: "flex",
    alignItems: 'center',
    padding: '80px 112px',
    gap: '20px'
  },
  "& .contentWrapper":{
    padding:"60px 200px 120px 112px",
    top: '296px',
    gap: '40px',
    opacity: '0px'
  },
  "& .condTitle":{
    fontFamily: 'Lato',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'left',
    color:'#D3D3D3',
    paddingBottom: '16px'
  },
  "& .termsContent":{
    fontFamily: 'Lato',
    fontWeight: 500,
    lineHeight: '24px',
    textAlign: 'left',
    color:'#7C7C7C',
    fontSize:' 16px',
    paddingBottom: '16px'

  },
  "& .checkBox":{
  width: "20px",
  height: "20px",
  cursor: "pointer",  
  paddingLeft:"0px",
  marginRight: '8px',
  color: '#D3D3D3',
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
  '&.Mui-checked': {
    color: '#00FF00 !important', // Checked color green
  },
},
'& .customBtn':{
  minWidth: '116px',
  height: '48px',
  marginTop: '16px',
},
'& .btntypo':{
  fontFamily: 'Lato',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#242424',
  textTransform: 'none',
  padding:"12px 20.5px"
}
});
// Customizable Area End
