import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
export interface CountryCodeObj {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}
export interface LandingPageResponse{
  message: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  employeeData: {
    dailNumber: string;
    PhoneNumberValidationCheck:number;
  };
  userName:string;
  userEmail:string;
  userMessage:string;
  phoneNumberStatus: string;
  isLoader:boolean;
  emailError:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
      // Customizable Area Start
      landingPageApiCallId: string = "";
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
       // Customizable Area Start
       getName(MessageEnum.RestAPIResponceMessage),
       // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      employeeData: {

        dailNumber: "",
        PhoneNumberValidationCheck: 100,
      },
      phoneNumberStatus: "",
      userName: "",
      userEmail: "",
      userMessage: "",
      isLoader: false,
      emailError:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.landingPageApiCallId) {
           if(responseJson && !responseJson.errors){
            this.landingPageSuccessCallback(responseJson);
           }
         } 
        }
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  landingPageSuccessCallback = async (responseJson: LandingPageResponse) => {
    if (responseJson && responseJson?.message) {
      this.setState({
        userName: "",
        userEmail: "",
        userMessage: "",
        employeeData: {
          dailNumber: "",
          PhoneNumberValidationCheck: 0
        },isLoader:false
      })
      await toast.success("Your message has been submitted successfully, we will get back to you in next 24 hours",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
    }
  }

  dailcodeData = async (value: string, data: CountryCodeObj, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    let dailCodeLenght= data?.dialCode?.length;
    let numberData = data?.format?.split('.').length - 1;
    let reference = this.state.employeeData
    reference.dailNumber = value    
    reference.PhoneNumberValidationCheck = numberData
   
    this.setState({
      employeeData: reference,
    }, () => { 
      if (this.state.employeeData.dailNumber.length !== this.state.employeeData.PhoneNumberValidationCheck) {
        this.setState({ phoneNumberStatus: `Please write ${numberData-dailCodeLenght} digits instead of ${this.state.employeeData.dailNumber.length-dailCodeLenght}`});
      } else {
        this.setState({ phoneNumberStatus: "" });
      }
    });
  }; 

  getCurrentYear = () => {
    return new Date().getFullYear();
  };

  landingPageAPI = async  () =>{
    this.setState({isLoader:true});
    const {
      dailNumber,
    } = this.state.employeeData;
    const { userName,
      userEmail,
      userMessage } = this.state;
      if(!dailNumber || !userName || !userEmail || !userMessage){
        toast.error("Please enter all details!",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
        this.setState({isLoader:false});
      }else{
        this.landingPageApiCallId = await this.landingPageAPICall();
      }
  }

  landingPageAPICall = async () => {
    const {
      dailNumber,
    } = this.state.employeeData;
    const { userName,
      userEmail,
      userMessage } = this.state;
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    
    const httpBody = {
      data:
      {
        "name": userName,
        "email": userEmail,
        "phone_number": dailNumber,
        "details": userMessage
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPageEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;

  };

  handleFirstName =(event:React.ChangeEvent<{value: string }> )=>{
    const firstName = event.target.value;
    this.setState({
            userName: firstName
    });
  };
  
  handleEmailChange = (event: React.ChangeEvent<{ value: string }>) => {
    const emailRegex = configJSON.emailRegex;
    const emailName = event.target.value;
    this.setState({
      userEmail: emailName,
    });
    if (!emailRegex.test(emailName)) {
      this.setState({ emailError: true });
    } else {
      this.setState({ emailError: false });
    }
  }

  handleMessageChange=(event: React.ChangeEvent<{value: string }>)=>{
    const mesageName = event.target.value;
    this.setState({
            userMessage: mesageName
    });
  };

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
