import React from "react";
// Customizable Area Start
import { styled } from '@mui/system';
import { Box, Grid, Avatar, Typography, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Headers from '../../../components/src/Headers.web';
import { arrowIconBack, editIcon } from "./assets";
import EmployeHeader from '../../../components/src/EmployeHeader.web'
import PhoneInput from "react-phone-input-2";
import UserProfileBasicController, { Props, configJSON } from "./UserProfileBasicController.web";
const profileImg = require("../assets/image.png");
import SuccessDialog from '../../../components/src/SuccessDailogox.web';
// Customizable Area End

export default class UserProfileEdit extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderUserProfileImage = () => {
        const { selectedImage, userProfilePerticulerCompany } = this.state;
        const imageSrc = selectedImage ? URL.createObjectURL(selectedImage) : userProfilePerticulerCompany.image || profileImg;

        return (
            <>
                <Box className="userImage" data-testId="avatarBoxTestId" style={{
                    border: (this.state.imgSizeExceed || this.state.invalidFileType) ? "3px solid red" : "3px solid #00FF00"
                    }}>
                    <img src={imageSrc} alt="" className="userProfileImage" />
                    <input
                        onChange={this.handleAvatarFileChange}
                        data-testId="setUserProfileTestId"
                        ref={this.fileInputRef}
                        type="file"
                        className="imgUploadInput"
                        hidden
                    />
                </Box>
            </>
        );
    };


    renderUsersProfile1 = () => {
        return (
            <div style={{height:"100%"}}>
            <UserProfileBasicStyle style={{overflowY:"auto", height: "calc(100% - 71px)",boxSizing:"border-box"}}>
                <div className="root1">
                    <Grid spacing={4} style={{ padding: "20px 10px" }}>
                        <Grid item xs={11} className="profileHeader">
                            <div>
                                <div style={{ display: "flex" }}>
                                    <img src={arrowIconBack} alt="ArrowIcon" style={{ cursor: "pointer", marginRight: "5px" }} data-testId="btnback1" onClick={() => this.goToScreen("UserProfileBasicWeb")} />
                                    <Typography className="ProfileName">Profile</Typography>
                                </div>
                                <Typography style={{ margin: "15px 0" }} className="profileName2" >Contact Details</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={11} className="statsContainar">
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "20px",flexWrap:"wrap"}} >
                                <div>
                                    {this.renderUserProfileImage()}
                                    <Typography 
                                        className="uploadTextThree" 
                                        style={{ color: (this.state.imgSizeExceed || this.state.invalidFileType) ? "red" : "inherit" }}>
                                        {(this.state.imgSizeExceed || this.state.invalidFileType) ? configJSON.uploadTextErr : configJSON.uploadText}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <Button
                                        variant="contained"
                                        onClick={this.onChooseFile}
                                        style={{ marginLeft: "8px", backgroundColor: (this.state.imgSizeExceed || this.state.invalidFileType) ? "red" : "#00ff00" }}
                                        className="button">
                                        <img
                                            src={editIcon}
                                            alt="Arrow Icon"
                                            style={{ marginRight: "5px", cursor: "pointer" }}
                                            data-testId="btnback"
                                        />
                                        Change Photo
                                    </Button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={11} className="inputContainarParent">
                            <Box className="inputContainar" >
                                <Typography className="LableName">Contact Person Name*</Typography>
                                <input
                                    autoComplete="off"
                                    className={this.state.personNameError ? "inputStyle errorStyle" : "inputStyle"}
                                    type="text"
                                    placeholder="Enter contact person name"
                                    data-testId="personName"
                                    name="personName"
                                    value={this.state.userProfilePerticulerCompany.personName}
                                    onChange={this.handleEditDescription}
                                />
                                {this.state.personNameError && (
                                    <Typography className="errorMessage" data-testId="personNameError">
                                        {configJSON.cityErrorMessage}
                                    </Typography>
                                )}
                            </Box>

                            <Box className="inputContainar" >
                                <Typography className="LableName">Phone Number*</Typography>
                                <Box className="phoneNumberInputFiled">
                                    <PhoneInput
                                        data-testId="phoneInput"
                                        placeholder="+91 Enter phone number"
                                        enableSearch={true}
                                        value={this.state.userProfilePerticulerCompany.phoneNumber?.toString()}
                                        onChange={this.dailcodeData}
                                        inputProps={{
                                            id: "PhoneNumber",
                                            required: true,
                                        }}
                                        disableSearchIcon={true}
                                        enableLongNumbers={true}
                                        jumpCursorToEnd={true}
                                        autoFormat={true}
                                        inputStyle={{
                                            backgroundColor: "#242424",
                                            width: "100%",
                                            border: "none",
                                            height: "inherit",
                                            color: "#A7A7A7",
                                        }}
                                        dropdownStyle={{
                                            border: "none",
                                            color: "#A7A7A7",
                                            background: "none",
                                        }}
                                        containerStyle={{
                                            backgroundColor: "#242424",
                                            width: "98%",
                                            height: "46px",
                                            paddingLeft: "2%",
                                            color: "#A7A7A7",
                                            border: this.state.phoneNumberStatus ? "1px solid red" : "1px solid #FFFFFF",
                                        }}
                                    />
                                    <Typography className="errorMessage">
                                        {this.state.phoneNumberStatus}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>


                        <Grid item xs={11} className="inputContainarParent">
                            <Box className="inputContainar" >
                                <Typography className="lableName">Email*</Typography>
                                <input
                                    className={"inputStyle"}
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter email id"
                                    name="email"
                                    data-testId="emailInput"
                                    value={this.state.userProfilePerticulerCompany.email}
                                    disabled={true}
                                    style={{ fontWeight: "bold", color: "#A7A7A7" }}
                                />
                            </Box>
                        </Grid>

                        <Typography className="profileName2" style={{ margin: "15px 0" }}>Company Details</Typography>

                        <Grid item xs={11} className="inputContainarParent">
                            <Box className="inputContainar" >
                                <Typography className="lableName">Company Name*</Typography>
                                <input
                                    className={"inputStyle"}
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter company name"
                                    name="companyName"
                                    data-testId="companyName"
                                    value={this.state.userProfilePerticulerCompany.companyName}
                                    disabled={true}
                                    style={{ fontWeight: "bold", color: "#A7A7A7" }}
                                />
                            </Box>

                            <Box className="inputContainar" >
                                <Typography className="lableName">Website Link*</Typography>
                                <input
                                    className={
                                        this.state.websiteLinkError ? "inputStyle errorStyle" : "inputStyle"
                                    }
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter website link"
                                    name="websiteLink"
                                    value={this.state.userProfilePerticulerCompany.websiteLink}
                                    onChange={this.handleEditDescription}
                                    data-testId="websiteLink"
                                />
                                {this.state.websiteLinkError &&
                                    <Typography className="errorMessage"> {configJSON.websiteLinkError}</Typography>}
                            </Box>
                        </Grid>

                        <Grid item xs={11} className="inputContainarParent">
                            <Box className="inputContainar" >
                                <Typography className="lableName">
                                    Tax Identification Number*
                                </Typography>
                                <input
                                    className={
                                        this.state.taxIdentificationNumberError ? "inputStyle errorStyle" : "inputStyle"
                                    }
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter tax identification number"
                                    name="taxIdentificationNumber"
                                    value={this.state.userProfilePerticulerCompany.taxIdentificationNumber}
                                    onChange={this.handleEditDescription}
                                    data-testId="taxIdentificationNumber"
                                />{this.state.taxIdentificationNumberError &&
                                    <Typography className="errorMessage"> {configJSON.taxIdentificationNumberError}</Typography>}
                            </Box>
                            <Box className="inputContainar">
                                <Typography className="lableName">Country*</Typography>
                                <select
                                    data-testId="countrySelect"
                                    onChange={this.getStateList}
                                    value={this.state.userProfilePerticulerCompany.countryName}
                                    id="option"
                                    className="inputStyle"
                                    placeholder="Select Country"
                                >
                                    <option value="" disabled style={{ display: "none" }}>Select Country</option>
                                    {this.state.countryList.map((item, index) => (
                                        <option key={index} value={item?.name}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                            </Box>

                        </Grid>

                        <Grid item xs={11} className="inputContainarParent">
                            <Box className="inputContainar" >
                                <Typography className="lableName">Address of The Company*</Typography>
                                <input
                                    className={
                                        this.state.companyAddressError ? "inputStyle errorStyle" : "inputStyle"
                                    }
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter address of the company"
                                    name="companyAddress"
                                    value={this.state.userProfilePerticulerCompany.companyAddress}
                                    onChange={this.handleEditDescription}
                                    data-testId="companyAddress"
                                />
                                {this.state.companyAddressError && (
                                    <Typography className="errorMessage">
                                        {configJSON.companyAddressError}
                                    </Typography>
                                )}
                            </Box>

                            <Box className="inputContainar">
                                <Typography className="LableName">Address Line 2</Typography>
                                <input
                                    className={this.state.companyAddressErrorTwo ? "inputStyle errorStyle" : "inputStyle"}
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter Address Line 2"
                                    name="companyAddressTwo"
                                    data-testId="AddressLine2"
                                    onChange={this.handleEditDescription}
                                    value={this.state.userProfilePerticulerCompany.companyAddressTwo}
                                />
                                {this.state.companyAddressErrorTwo && (
                                    <Typography className="errorMessage">
                                        {configJSON.companyAddressError}
                                    </Typography>
                                )}
                            </Box>

                        </Grid>

                        <Grid item xs={11} className="inputContainarParent">
                            <Box className="inputContainar">
                                <Typography className="lableName">State*</Typography>
                                <input
                                    className={
                                        this.state.stateNameError ? "inputStyle errorStyle" : "inputStyle"
                                    }
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Select State"
                                    name="stateName"
                                    onChange={this.handleEditDescription}
                                    value={this.state.userProfilePerticulerCompany.stateNameCheck}
                                    id="option"
                                />
                            </Box>

                            <Box className="inputContainar">
                                <Typography className="lableName">{configJSON.zipCodeText}</Typography>
                                <input
                                    className={
                                        this.state.zipCodeError ? "inputStyle errorStyle" : "inputStyle"
                                    }
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter zip code"
                                    name="zipCode"
                                    value={this.state.userProfilePerticulerCompany.zipCode}
                                    onChange={this.handleEditDescription}
                                    data-testId="ZipCodeTest"
                                /> {this.state.zipCodeError &&
                                    <Typography className="errorMessage"> {configJSON.zipCodeError}</Typography>}
                            </Box>
                        </Grid>
                        <Grid item xs={11} className="inputContainarBTNParent">
                            <Box className="inputContainarBTN" >
                                <Button variant="contained"
                                    className={this.state.btnStatus ? "disabledButton buttonBT" : "button buttonBT"}
                                    style={{color: this.state.btnStatus ? "#00FF00" : "black"}} 
                                    disabled={this.state.btnStatus}
                                    data-testId="UpdateTestBTN"
                                    onClick={this.UpdateUserProfilePerticulerCompanyFunction}   >
                                    Save Changes
                                </Button>
                            </Box>

                            <Box className="inputContainarBTN" >
                                <Button variant="outlined" className="buttonChangePassword" onClick={() => this.goToScreen("UserProfileBasicWeb")} data-testId="btnTest" >
                                    Cancel
                                </Button>
                            </Box>

                        </Grid>

                    </Grid>
                </div>
                <SuccessDialog
                    onClose={this.handleCancel}
                    open={this.state.editSuccessDialogOpen}
                    disc={"Profile information successfully saved"}
                />
            </UserProfileBasicStyle>
            </div>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start    
            <>
                {
                    this.state.role === "company" ?
                        <Headers isRouteData={"UserProfileBasicWeb"} sliderButton={this.state.sideBarClose}
                            logOutToogle={this.logOutBtn}
                            data-testId="logoutTestId"
                            onToggleClick={this.handleSliderButton}
                            goToScreen={this.goToScreen}
                        >
                            {this.renderUsersProfile1()}
                        </Headers>
                        :
                        <EmployeHeader isRouteData={"UserProfileBasicWeb"}
                            sliderButton={this.state.sideBarClose}
                            logOutToogle={this.logOutBtn}
                            data-testId="logoutTestId"
                            onToggleClick={this.handleSliderButton}
                            goToScreen={this.goToScreen}
                        >
                            {this.renderUsersProfile1()}
                        </EmployeHeader>
                }
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export const UserProfileBasicStyle = styled(Box)({
    "& .root1": {
        minHeight: "100vh",
        backgroundColor: "#242424",
        color: "#fff",
        overFlowX: "hidden",
        padding: "32px",
        "@media (max-width: 1200px)": {
            padding: "32px 15px",
        },
    },
    "& .inputContainarParent": {
        width: "100%", 
        display: "flex", 
        justifyContent: "space-between",
        flexWrap:"wrap",
        gap:"10px"
    },
    "& .inputContainar": {
        flexDirection: "column",
        display: "flex",
        marginTop: "3%",
        width: "45%",
        minWidth:"400px",
        "@media (max-width: 650px)": {
            minWidth:"300px",
          },
    },
    "& .inputContainarBTNParent": {
        display: "flex",        
        width: "100%" ,
        "@media (max-width: 600px)": {
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
       
    },
    "& .inputContainarBTN": {
        flexDirection: "column",
        display: "flex",
        marginTop: "3%",
    },
    "& .LableName": {
        fontSize: "16px",
        fontWeight: 600,
        paddingBottom: "4px",
        fontFamily: "Lato",
        lineHeight: "22px",
        color: "#D3D3D3",
        textAlign: "left",
    },
    "& .inputStyle": {
        height: "47.6px",
        fontWeight: 400,
        backgroundColor: "#242424",
        color: "#A7A7A7",
        border: "1px solid #FFFFFF",
        padding: "12px",
        width: "100%",
        fontFamily: "Lato",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "left",
    },
    "& .inputStyle:focus": {
        outline: "none",
    },
    "& .errorStyle": {
        border: "1px solid red",
    },
    "& .profileHeader": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "16px",
    },
    "& .avatar": {
        width: "160px",
        border: "2px solid #00ff00",
        height: "160px",
    },
    "& .ProfileName": {
        fontSize: '40px',
        color: "#FCFCFC",
        fontFamily: 'Lato',
        fontWeight: 600,
        lineHeight: '48px',
        letterSpacing: '-0.02em',
        textAlign: 'left',
    },
    "&. phoneNumberInputFiled": {
        display: "flex"
    },
    "& .profileName2": {
        textAlign: 'left',
        fontSize: '24px',
        fontWeight: 700,
        color: "#FCFCFC",
        fontFamily: 'Lato',
    },
    "& .profileName3": {
        fontFamily: 'Lato',
        fontSize: '12px',
        fontWeight: 600,
        color: "#EFEFEF"
    },
    "& .react-tel-input": {
        height: "46px",
        width: "30%",
    },
    "& .react-tel-input .form-control": {
        width: "74%",
        backgroundColor: "rgb(37 37 37) !important",
        paddingLeft: "28px",
    },
    "& .react-tel-input .selected-flag": {
        width: "0px",
        borderRadius: "0px",
        padding: "0px",
    },

    "& .react-tel-input .country-list": {
        margin: "-250px 0 0px -10px",
        boxShadow: "1px 2px 10px rgba(0, 0, 0, .35)",
        zIndex: 37,
        backgroundColor: "#2f2f2f !important",
        width: "377px",
        borderRadius: "7px",
    },
    "& .react-tel-input .flag-dropdown": {
        border: "none",
        background: "none",
    },
    "& .flag-dropdown::hover": {
        border: "none",
        background: "none",
    },
    "& .buttonChangePassword": {
        backgroundColor: "#242424",
        color: "#00FF00",
        border: "1px solid #FFFFFF",
        marginLeft: "16px",
        fontFamily: 'Lato',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 600,
        textAlign: 'center',
        width: "162px",
        padding: "10px",
        whiteSpace: 'nowrap',
        "&:hover": {
            color: "#000",
            backgroundColor: "#00e000",
        },
        "@media (max-width: 600px)": {
            marginLeft: "0px",
          },
    },
    "& .button": {
        backgroundColor: "#00ff00",
        marginLeft: "0px",
        fontWeight: 600,
        fontFamily: 'Lato',
        textAlign: 'center',
        fontSize: '16px',
        padding: "10px",
        width: "162px",
        textTransform: 'none',
        color: "#242424",
        "&:hover": {
            backgroundColor: "#00e000",
        },
    },
    "& .disabledButton": {
        backgroundColor: "#212521",
        marginLeft: "0px",
        fontWeight: 600,
        fontFamily: 'Lato',
        textAlign: 'center',
        fontSize: '16px',
        padding: "10px",
        width: "162px",
        textTransform: 'none',
        color: "#00FF00",
        border: "1px solid white",
    },
    "& .statsContainar": {
        display: "flex",
        marginBottom: "16px",
        justifyContent: "space-between",
    },
    "& .tabsStyle .MuiTab-textColorPrimary.Mui-selected": {
        backgroundColor: "#00FF00",
    },
    "& .errorMessage": {
        color: "red",
    },
    "& .userImage": {
        width: "150px",
        height: "150px",
        border: "3px solid #00FF00",
        borderRadius: "50%",
        marginTop: "25px",
        marginBottom: "10px",
    },
    "& .userProfileImage": {
        width: "100%",
        height: "100%",
        borderRadius: "50%",
    },
    "& .uploadTextThree": {
        color: "#EFEFEF",
        fontSize: "14px",
        fontFamily: "Lato",
        fontWeight: 400,
        marginBottom: "10px",
        lineHeight: "22px",
    },
});
// Customizable Area End