Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";
exports.employeeSignupEndPoint = "account_block/create_employee";
exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.personRegex = /^[a-zA-Z]*$/;
exports.emailRegex = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.addressRegex = /^[a-zA-Z0-9]*$/;
exports.comapnyRegex = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/;
exports.companyAddressError = "You are not able to write more than 100 words";
exports.cityErrorMessage = "You are not able to write more than 42 words";
exports.companyNameError = " You are not able to write more than 52 words";
exports.companyNameExit = "Company Name already exists.";

exports.taxRegex =  /^[a-zA-Z0-9\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;
exports.websiteLinkError = "Please enter valid website link";
exports.webSiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;
exports.companySignupEndPoint = "account_block/create_company";
exports.accountSubscriptionEndPoint = "bx_block_custom_user_subs/subscriptions/account_subscription";
exports.phoneNumberText = "Phone Number";
exports.zipCodeText = "Zip Code";


// Customizable Area End
