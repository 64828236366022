import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Input,
  TextField
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";

import ContactusController, { Props , configJSON,} from "./ContactusController.web";
import { styled } from '@material-ui/styles';
import Headers from '../../../components/src/Headers.web';
import EmployeHeader from '../../../components/src/EmployeHeader.web';
import SuccessDialog from '../../../components/src/SuccessDailogox.web';
// Customizable Area End
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#0000ff',
      contrastText: '#fff',
    },
  },
});
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }
 // Customizable Area Start
  renderContactUs = () => {
    return <>
        <StyledContainer maxWidth="sm" style={{height: "100%"}}>
          <BoxContainer  style={{overflowY:"auto", height: "calc(100% - 71px)",boxSizing:"border-box"}}>
            <Grid className='headerBox' container spacing={2} alignItems="center">
              <Grid style={{ padding: "0px 16px" }} item>
                <Typography className='headerTxt' variant="h5">Contact Us</Typography>
              </Grid>
            </Grid>
            <form noValidate autoComplete="off">
            <Grid>

              <div style={{display:"flex",flexWrap:"wrap",flexDirection:"row",gap:"10px",width:"100%"}}>
              <Grid item xs={6}>
              <label className="labelText">Name <span style={{ color: 'red' }}>*</span></label>
                <CustomTextField
                  fullWidth
                  margin="normal"
                  name="name"
                  placeholder="Enter Name"
                  value={this.state.name}
                  onChange={this.handleChange}
                  variant="outlined"
                  className="textName"
                  data-testId="CompanyNameTest"
                  error={Boolean(this.state.nameError)}
                  helperText={this.state.nameError}
                />
              </Grid>
              <Grid item xs={6}> 
              <label className="labelText">Subject <span style={{ color: 'red' }}>*</span></label>
                <CustomTextField
                  fullWidth
                  margin="normal"
                  name="comments"
                  placeholder="Enter Subject"
                  value={this.state.comments}
                  onChange={this.handleChange}
                  variant="outlined"
                  className="textName"
                  data-testId="CommentsTest"
                />
              </Grid>
              </div>

              <div style={{display:"flex",flexWrap:"wrap",gap:"10px",width:"100%"}}>              
              <Grid item xs={6} >
              <label className="labelText">Email <span style={{ color: 'red' }}>*</span></label>
                <CustomTextField
                  fullWidth
                  margin="normal"
                  name="email"
                  placeholder="Enter Email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  variant="outlined"
                  className="textName"
                  error={Boolean(this.state.emailError)}
                  helperText={this.state.emailError}
                  data-testId="emailTest"
                />
              </Grid>
              <Grid item xs={6} >
              <label className="labelText">Details <span style={{ color: 'red' }}>*</span></label>
                <CustomTextField
                  fullWidth
                  margin="normal"
                  name="details"
                  placeholder="Enter Details"
                  value={this.state.details}
                  onChange={this.handleChange}
                  variant="outlined"
                  className="textName"
                  data-testId="detailsTest"
                />
              </Grid>
              </div>

              </Grid>
             
              <Grid style={{marginTop:"24px"}} container spacing={2}>
                <Grid item>
                  <Button data-testId="formDataTestId" className='btnIEmp colorBtn' variant="contained"  disabled={this.state.isButtonDisabled} onClick={this.contactUsApi}>
                    <Typography>Submit</Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </BoxContainer>
        </StyledContainer>
        <SuccessDialog
          disc={configJSON.sucessMsg}
          open={this.state.successDialogOpen}
          onClose={this.handleCancel}
        /> 
      </>
  }
 // Customizable Area End
  render() {
    return (
      // Customizable Area Start
    <>
      {
        this.state.role === "company" ? 
        <Headers sliderButton={this.state.sideBarClose}
          onToggleClick={this.handleSliderButton}
          logOutToogle={this.logOutBtn} 
          data-testId="logoutTestId"
          goToScreen = {this.goToScreen}
          isRouteData={"Contactus"}
        >
          {this.renderContactUs()}         
        </Headers> 
      :
      <EmployeHeader sliderButton={this.state.sideBarClose}
          onToggleClick={this.handleSliderButton}
          logOutToogle={this.logOutBtn} 
          data-testId="logoutTestId"
          goToScreen = {this.goToScreen}
          isRouteData={"Contactus"}
        >  
          {this.renderContactUs()}
      </EmployeHeader>
      }
    </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledContainer = styled(Container)({
  maxWidth:"100vw !important",
  backgroundColor: "#242424",
  paddingTop: "24px"
})

const CustomTextField = styled(TextField)(({ error }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: error ? "red" : theme.palette.grey[400]
    },
    '&:hover fieldset': {
      borderColor: error ? "red" : theme.palette.grey[600],
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? "red" : theme.palette.grey[400]
    },
  },
}));

const BoxContainer = styled(Box)({
  height:"496px",
  top:"120px",
  "& .labelText":{
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color:"#EFEFEF"
  },
  "& .textName":{
    color:"white",  
    '& .MuiOutlinedInput-root': {

      '& .MuiInputBase-input::placeholder': {
        color: '#7C7C7C',
        opacity: 0.3,
      },
      '& .MuiInputBase-input': {
        fontFamily: 'Lato, sans-serif',
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.01em",
        textAlign: "left",
        color: "#D3D3D3",
        width:"400px",
        "@media (max-width: 1400px)": {
          width:"300px",
        },
        "@media (max-width: 1200px)": {
          width:"250px",
        },
      },
    },
  },
  "& .headerBox":{
    height:"48px",
    margin:"0px 0px 32px 0px",
  },
  "& .headerTxt":{
    fontFamily:"Lato",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: '48px',
    letterSpacing: "-0.02em",
    textAlign: "left",
    color:"#FCFCFC"
  },
  "& .btnIEmp":{
    width:"162px",
    height:"48px",
    padding:"12px 20px",
    background:"transparent",
    border:"1px solid",
    borderRadius:"0px",
    textTransform: "capitalize",
    transition: "background 0.3s ease" 
  },
  "& .btnIEmp:hover":{
    background: "#00FF00",
  },
  "& .colorBtn":{
    background: "#00FF00",
    color:"#242424",
    fontFamily:"Lato",
    fontSize: "16px",
    fontWeight: "600"

  },
  "& .Mui-disabled":{
    backgroundColor: "#7C7C7C !important",
    color: "#242424 !important"
  }
})

// Customizable Area End
