import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Typography,
  styled,
} from "@material-ui/core";
import EmailAccountRegistrationController, {
  Props,
  configJSON,
} from "./EmailAccountRegistrationController.web";
import { checked, logo, mainImage, uncheck, arrowIconBack } from "./assets";
import PhoneInput from "react-phone-input-2";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// Customizable Area End

export default class EmployeeSignup extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderStep1 = () => {
    return (
      <Box style={{ marginBottom: "5px" }}>
        <Box className="inputContainer">
          <Typography className="lableName">Company ID*</Typography>
          <input
            onChange={this.handleEmployeeData}
            placeholder="Enter company ID"
            className={`inputFieldStyle ${this.state.error ? "error" : ""}`}
            name="companyId"
            onKeyDown={this.handleKeyDown}
            value={this.state.employeeData.companyId}
            type="text"
            data-test-id="companyIdtest"
            autoComplete="off"
          />
          {this.state.error && (
            <Typography className="errorMessage">{this.state.error}</Typography>
          )}
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">Employee Name*</Typography>
          <input
            className={`${
              this.state.employeeData?.employeeName.length < 52
                ? "inputFieldStyle"
                : "inputFieldStyle error"
            }`}
            type="text"
            name="employeeName"
            data-test-id="employeeNameIdtest"
            onChange={this.handleEmployeeData}
            onKeyDown={this.handleKeyDown}
            value={this.state.employeeData.employeeName}
            placeholder="Enter employee name"
            autoComplete="off"
          />
          {this.state.employeeData?.employeeName.length < 52 || (
            <Typography className="errorMessage">
              {configJSON.companyNameError}
            </Typography>
          )}
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">Phone Number</Typography>
          <Box style={{ display: "flex" }}>
            <PhoneInput
              data-test-id="phoneInput"
              placeholder="+91 Enter phone number"
              enableSearch={true}
              value={this.state.employeeData.dailNumber?.toString()}
              onChange={this.dailcodeData2}
              inputProps={{
                required: true,
                id: "PhoneNumber",
              }}
              enableLongNumbers={true}
              disableSearchIcon={true}
              jumpCursorToEnd={true}
              autoFormat={true}
              inputStyle={{
                width: "100%",
                border: "none",
                backgroundColor: "#242424",
                color: "#A7A7A7",
                height: "inherit",
              }}
              dropdownStyle={{
                color: "#A7A7A7",
                border: "none",
                background: "none",
              }}
              containerStyle={{
                width: "100%",
                height: "46px",
                backgroundColor: "#242424",
                color: "#A7A7A7",
                border: this.state.phoneNumberStatus
                  ? "1px solid red"
                  : "1px solid #FFFFFF",
                paddingLeft: "2%",
              }}
              onKeyDown={this.handleKeyDown}
            />
          </Box>
          <Typography className="errorMessage">
            {this.state.phoneNumberStatus}
          </Typography>
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">Employee Email*</Typography>
          <input
            name="employeeEmail"
            value={this.state.employeeData.employeeEmail}
            onChange={this.handleEmployeeData}
            className={`inputFieldStyle ${
              this.state.errorEmail ? "error" : ""
            } ${
              this.state.emailError
                ? "inputFieldStyle error"
                : "inputFieldStyle"
            }`}
            type="text"
            placeholder="Enter email id"
            autoComplete="off"
            data-test-id="employeeEmailIdtest"
            onKeyDown={this.handleKeyDown}
          />
          <Typography className="errorMessage">
            {this.state.emailError}
          </Typography>
          {this.state.errorEmail && (
            <Typography className="errorMessage">
              {this.state.errorEmail}
            </Typography>
          )}
        </Box>
        <Button
          className="customizeButton"
          data-test-id="EmpContinueBtn"
          disabled={this.state.btnStatus}
          onClick={this.createEmployeeAccount}
          style={
            this.state.btnStatus
              ? { backgroundColor: "#7C7C7C" }
              : { backgroundColor: "#00FF00" }
          }
        >
          Continue
        </Button>
      </Box>
    );
  };

  renderStep2 = () => {
    return (
      <Box style={{ marginTop: "2%" }}>
        <Box className="inputContainer" data-test-id="render2">
          <Typography className="lableName">Password</Typography>
          <Input
            placeholder="Your Password"
            className="inputFieldStyle"
            type={this.state.enablePasswordField ? "password" : "text"}
            data-test-id="empPassworrd"
            name="empPassworrd"
            inputProps={{ disableUnderline: true,maxLength: 32 }}
            autoComplete="off"
            onChange={this.handleEmployeeData}
            style={
              this.state.validPass
                ? { border: "1px solid #FFFFFF" }
                : { border: "1px solid red" }
            }
            value={this.state.employeeData.empPassworrd}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={this.handlePasswordShow}
                  aria-label="toggle password visibility"
                >
                  {this.state.enablePasswordField ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {this.state.validPass ? null : (
            <Typography className="errorMsg">
              Kindly meet all the provided conditions
            </Typography>
          )}
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">Confirm New Password </Typography>
          <Input
            placeholder="Enter Confirm Password"
            data-test-id="empConfirmPass"
            name="empConfirmPass"
            autoComplete="off"
            className="inputFieldStyle"
            style={
              this.state.confirmPass
                ? { border: "1px solid red" }
                : { border: "1px solid #FFFFFF" }
            }
            type={this.state.enableReTypePasswordField ? "password" : "text"}
            value={this.state.employeeData.empConfirmPass}
            onChange={this.handleEmployeeData}
            inputProps={{ disableUnderline: true,maxLength: 32 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" onClick={this.handleConfirmPasswordShow}>
                  {this.state.enableReTypePasswordField ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {this.state.confirmPass ? (
            <Typography className="errorMsg">Password must be same </Typography>
          ) : null}
        </Box>
        <Box><LebelStyle variant="h6"  className="passCondLable">Your password must contain</LebelStyle></Box>
        <Box className="controlBox">
          <FormControlLabel
            disabled
            className="checkContainer"
            control={
              <Box className="checkImg">
                {this.employeePasswordCheck("alphabet") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <LebelStyle variant="h6" style={{ color: "white" }}>
                At least one alphabet
              </LebelStyle>
            }
          />
          <FormControlLabel
            disabled
            className="checkContainer"
            control={
              <Box className="checkImg">
                {this.employeePasswordCheck("number") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <LebelStyle variant="h6" style={{ color: "white" }}>
                At least one number
              </LebelStyle>
            }
          />
          <FormControlLabel
            disabled
            className="checkContainer"
            control={
              <Box className="checkImg">
                {this.employeePasswordCheck("specialSign") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <LebelStyle variant="h6" style={{ color: "white" }}>
                At least one special character
              </LebelStyle>
            }
          />
          <FormControlLabel
            disabled
            control={
              <Box className="checkImg">
                {this.employeePasswordCheck("capitalChar") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            className="checkContainer"
            label={
              <LebelStyle variant="h6" style={{ color: "white" }}>
                At least one character in caps
              </LebelStyle>
            }
          />
          <FormControlLabel
            disabled
            className="checkContainer"
            control={
              <Box className="checkImg">
                {this.employeePasswordCheck("minimumChar") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <LebelStyle variant="h6" style={{ color: "white" }}>
                Minimum character length is 8 characters
              </LebelStyle>
            }
          />
        </Box>
        <Button
          data-test-id="finalContinueBtn"
          className="customizeButton"
          style={
            this.state.enableBtn
              ? { backgroundColor: "#7C7C7C" }
              : { backgroundColor: "#00FF00" }
          }
          disabled={this.state.enableBtn}
          onClick={this.createEmployeeAccount}
        >
          {this.state.Loader ? <CircularProgress thickness={4} size={30} /> : "Continue"}
        </Button>
      </Box>
    );
  };

  render() {
    return (
      <EmployeeSignupStyle
        style={{ overflow: "hidden", width: "100%", height: "100%" }}
      >
        <Box>
          <Grid item xl={12} xs={12} lg={12} sm={12}>
            <Box
              style={{
                backgroundColor: "#242424",
                width: "100%",
                borderBottom: "1px solid #505050",
              }}
            >
              <img
                alt="logo"
                src={logo}
                style={{
                  display: "flex",
                  marginLeft: "auto",
                  paddingRight: "2%",
                }}
              />
            </Box>
          </Grid>
        </Box>
        <Box>
          {this.state.step >= 0 ? (
            <Grid container>
              <Grid
                item
                xs={12}
                md={7}
                xl={7}
                sm={12}
                lg={7}
                style={{ backgroundColor: "#242424" }}
              >
                <Box className="main-container">
                  <Box style={{ width: "50%", margin: "auto" }}>
                    <img
                      data-test-id="btnback"
                      style={{ marginBottom: "2px", cursor: "pointer" }}
                      onClick={this.handleBackClick2}
                      src={arrowIconBack}
                      alt="Arrow Icon"
                    />
                    <Box style={{ color: "white" }}>
                      <Typography className="createAccount">
                        Create an Account
                      </Typography>
                    </Box>
                    {this.state.step === 1 ? this.renderStep1() : null}
                    {this.state.step === 2 ? this.renderStep2() : null}
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>            
               
              <img             
              src={mainImage} 
              className="main-img"  
              data-test-id="loadingImageTest"    
             />
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </EmployeeSignupStyle>
    );
  }
}
const EmployeeSignupStyle = styled(Box)({
  "& .MuiGrid-grid-md-7": {
    overflowY: "auto",
  },
  "& ::-webkit-scrollbar-track": {
    background: "#024714",
  },
  "& ::-webkit-scrollbar-thumb": {
    background: "#024714",
  },
  "& .main-img": {
    width: "100%",
    height: "calc(100vh - 71px)",
    objectFit: "cover",
    objectPosition: "bottom",
    "@media(max-width: 960px)": {
      display: "none",
    },
  },

  "& .MuiIconButton-edgeEnd": {
    marginRight: "0px",
  },
  "& .react-tel-input .flag-dropdown": {
    background: "none",
    border: "none",
  },
  "& .react-tel-input": {
    width: "30%",
    height: "46px",
  },
  "& .react-tel-input .form-control": {
    width: "74%",
    paddingLeft: "28px",
    backgroundColor: "rgb(37 37 37) !important",
  },
  "& .react-tel-input .selected-flag": {
    width: "0px",
    padding: "0px",
    borderRadius: "0px",
  },

  "& .react-tel-input .country-list": {
    zIndex: 37,
    margin: "-250px 0 0px -10px",
    boxShadow: "1px 2px 10px rgba(0, 0, 0, .35)",
    width: "377px",
    borderRadius: "7px",
    backgroundColor: "#2f2f2f !important",
  },
  "& .errorMsg": {
    color: "red",
    fontSize: "12px",
  },
  "& .MuiInputBase-input": {
    color: "white",
  },

  "& .main-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 71px)",
  },
  "& .flag-dropdown::hover": {
    background: "none",
    border: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .createAccount": {
    fontFamily: "Lato",
    letterSpacing: "-0.005em",
    fontSize: "30px",
    fontWeight: 700,
    color: "#D3D3D3",
    lineHeight: "40px",
    textAlign: "left",
  },
  "& .checkContainer": {
    marginTop: "2%",
  },
  "& .subheading": {
    fontSize: "1rem",
    color: "#D3D3D3",
  },

  "& .inputFieldStyle": {
    width: "100%",
    height: "47.6px",
    backgroundColor: "#242424",
    color: "#A7A7A7",
    border: "1px solid #FFFFFF",
    padding: "16px",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
  },
  "& .inputFieldStyle.error": {
    border: "1px solid red",
  },
  "& .errorMessage": {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
  },
  "& .inputContainer": {
    display: "flex",
    flexDirection: "column",
    marginTop: "3%",
  },
  "& .customizeButton": {
    width: "100%",
    height: "56px",
    textTransform: "none",
    marginTop: "2%",
    backgroundColor: "#00FF00",
    fontWeight: 600,
  },
  "& .inputFieldStyle:focus": {
    outline: "none",
  },
  "& .lableName": {
    fontSize: "16px",
    fontWeight: 700,
    color: "#D3D3D3",
    fontFamily: "Lato",
    lineHeight: "22px",
    textAlign: "left",
    paddingBottom: "4px",
  },
  "& .phoneinput": {
    borderLeft: "none",
  },
  "& .checkImg": {
    paddingRight: "2%",
  },
  "& .MuiSvgIcon-root": { color: "white" },

  "& .controlBox": {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginTop: "2%",
    marginLeft: "4%",
  },
  "& .checkBoxlabel": {
    color: "#D3D3D3",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
  },
  "& .loading-container": {
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  "& .spinner": {
    border: "6px solid rgba(0, 0, 0, 0.1)",
    height: "80px",
    borderRadius: "50%",
    width: "80px",
    animation: "spin 1s ease-in-out infinite",
    borderTopColor: "#000",
  },
  "& .loading-text": {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "10px",
  },
  "& .passCondLable":{
    color: "#D3D3D3",
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    marginTop: '16px',
  },
  scrollbarColor: "#024714 #f1f1f1",
});

const LebelStyle = styled(Typography)({
  fontSize: "12px",
  lineHeight: "unset",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "small",
  },
});
